.filterExport {
    width: 100%;
    display: flex;
    gap: 2%;
    align-items: center;
    justify-content: center;
    padding: 0 0 1% 0;
  }
  
  .filterExport p {
    width: 50%;
    margin: 0;
    font-size: 0.9rem;
    font-weight: 500;
  }
  
  .search {
    width: 13%;
  }
  
  .search input {
    width: 100%;
    background-color: #fff9f1;
    border-radius: 8px;
    background-image: url(../../assets/images/searchIcon.png);
    background-repeat: no-repeat;
    background-size: 10%;
    background-position-x: 90%;
    background-position-y: 45%;
    padding: 3%;
    font-size: 0.8rem;
    outline: none;
    border: none;
  }
  
  .search input:focus {
    background-image: none;
  }
  
  .export {
    border: 1px solid #e6e6e6;
    border-radius: 15px;
    width: 20%;
    cursor: pointer;
  }
  
  .export p {
    width: 100%;
    margin: 0;
    padding: 2.5%;
    font-size: 0.7rem;
    font-weight: 600;
    text-align: center;
  }
  
  .export > p > a {
    text-decoration: none;
    color: #000000;
  }
  .pageHeaderButton {
    width: 8%;
  }
  .pageHeaderButton button {
    width: 100%;
    font-weight: 400;
    font-size: 0.7rem;
    color: #ffffff;
    background: linear-gradient(95.2deg, #ff9914 0%, #ff4c04 54.17%);
    border: none;
    padding: 0 3%;
    height: 5.5dvh;
    border-radius: 8px;
  }

  .filterDiv {
    border: 1px solid #e6e6e6;
    border-radius: 15px;
    width: 8%;
    cursor: pointer;
  }

  .filterDiv p {
    width: 100%;
    margin: 0;
    padding: 5% 0;
    font-size: 0.7rem;
    font-weight: 600;
    text-align: center;
    background-image: url(../../assets/images/filter.png);
    background-size: 12px;
    background-position-x: 15%;
    background-position-y: 50%;
    background-repeat: no-repeat;
  }
  


  @media screen and (max-width: 767px) {

    .filterExport {
      flex-direction: column;
      gap: 2svh;
    }
  
    .filterExport p{
      width: 100%;
      margin: 0;
    }
    
    .search {
      width: 100%;
    }
    
    .search input {
      background-size: 5%;
    }
    
    .search input:focus {
      background-image: none;
    }
  
    .export {
      width: 100%;
    }
    
    
    .filterDiv {
      border: 1px solid #e6e6e6;
      border-radius: 15px;
      width: 30%;
    }

    .pageHeaderButton {
      width: 30%;
      margin-bottom: 2%;
    }
    
  }