.contentContainer{
  width: 98%;
  box-shadow: 0px 1px 8px 0px #00000026;
  margin-left: 1%;
  padding: 1%;
  border-radius: 12px;
  font-family: Poppins;
}

.staticsContentSearch{
  display:flex;
  justify-content: flex-end;
 

}
.staticsContentSearch input {
 
  background-color: #fff9f1;
  border-radius: 8px;
  background-image: url(../../../assets/images/searchIcon.png);
  background-repeat: no-repeat;
  background-size: 10%;
  background-position-x: 90%;
  background-position-y: 45%;
  padding: 1%;
 
  font-size: 0.6rem;
  outline: none;
  border: none;
} 

.staticsContentSearch input:focus {
  background-image: none;
} 

@media screen and (max-width: 767px) {

  .contentContainer {
    padding: 3% 1%;
  }

}