.main {
  width: 100%;
  font-family: Poppins;
  padding-bottom: 2%;
}

.contentContainer {
  width: 97.5%;
  box-shadow: 0px 1px 8px 0px #00000026;
  margin-left: 1%;
  padding: 1%;
  border-radius: 12px;
  font-family: Poppins;
}
.menuContainer {
  display: flex;
  gap: 1%;
  padding: 1% 2%;
  font-family: Poppins;
}
.singleMenu {
  width: 12%;
  text-align: center;
  padding: 0.5% 0 0 0;
}
.singleMenu p {
  width: 100%;
  margin: 0;
  font-size: 0.8rem;
  color: #000000;
  cursor: pointer;
  text-decoration: none;
  position: relative;
}

.singleMenu p::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100%;
  height: 1px;
  background-color: #999fa9;
}

.activeMenu {
  background-color: #ff991440;
}


.activeMenu p {
  color: #ff9914;
  font-family: Poppins;
  font-size: 0.8rem;
  font-weight: 600;
}

.activeMenu p::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100%;
  height: 1px;
  background-color: #ff4c04;
}

.activePage {
  margin-top: 3%;
}

.tableContainer {
  width: 97%;
  border-radius: 8px;
  margin-top: -1%;
}

.fieldContainer {
  width: 100%;
  display: flex;
}

.feildLeft {
  width: 50%;
}
.feildRight {
  width: 50%;
}

.fieldLabel {
  margin-top: 3%;
}
.fieldLabel p {
  margin: 0;
  font-size: 0.8rem;
  font-weight: 500;
}
.fieldInput {
  width: 80%;
  margin-top: 2%;
}

.fieldInput input {
  background-color: #fff9f1;
  outline: none;
  border: none;
  width: 100%;
  height: 3rem;
  border-radius: 8px;
  padding-left: 2%;
  font-size: 0.8rem;
}

.fieldInput textarea {
  background-color: #fff9f1;
  outline: none;
  border: none;
  width: 100%;
  height: 5rem;
  border-radius: 8px;
  resize: none;
  padding-left: 2%;
  font-size: 0.8rem;
}

.fieldDate {
  width: 80%;
  display: flex;
  gap: 2%;
}
.fieldDate input {
  background-color: #fff9f1;
  outline: none;
  border: none;
  width: 100%;
  height: 3rem;
  border-radius: 8px;
  padding-left: 2%;
  font-size: 0.8rem;
}

.footer {
  margin-top: 5%;
}

.error{
  margin: 0;
  margin-top: 0.5rem;
  font-size: 0.7rem;
  color: red;
}

input[type="text"]::placeholder {
  font-size: 0.7rem;
  padding-left: 20px;
}

input[type="date"] {
  appearance: none !important;
}

input[type="date"]:focus {
  outline: none;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("../../../../assets/images/dropdown.png") !important;
  background-repeat: no-repeat !important;
  background-size: 10px !important;
  background-position-x: 95% !important;
  background-position-y: 50% !important;
  background-repeat: no-repeat;
  padding-right: 10px !important;
  /* padding: 8px !important; */
  font-size: 14px !important;
  outline: none !important;
}

::-webkit-datetime-edit-month-field {
  color: #000000;
  background-color: #fff9f1;
}
::-webkit-datetime-edit-day-field {
  color: #000000;
  background-color: #fff9f1;
}
::-webkit-datetime-edit-year-field {
  color: #000000;
  background-color: #fff9f1;
}

select option {
  padding: 2px 0 !important;
}

@media screen and (max-width: 767px) {
  
  .menuContainer {
    width: 100%;
    display: flex;
    gap: 5%;
    padding: 1% 0%;
    font-family: Poppins;
    justify-content: center;
  }
  .singleMenu {
    width: 45%;
    padding: 2% 0 0 0;
    margin-top: 2%;
  }

  .tableContainer{
    width: 100%;
  }

  .fieldContainer {
    flex-direction: column;
    gap: 2svh;
  }

  .feildLeft {
    width: 100%;
  }
  .feildRight {
    width: 100%;
  }

  .fieldInput {
    width: 98%;
    margin-top: 2%;
    display: flex;
    justify-content: center;
  } 
  .fieldDate {
    width: 98%;
  }
  
}
