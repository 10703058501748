.modal {
    top: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    height: 100dvh;
    min-height: 80dvh;
    max-height: 100dvh;
    border-radius: 0 !important;
    width: 29dvw !important;
  }
  
  .ant-modal-content {
    height: 100dvh !important;
    max-height: 100dvh;
    height: calc(100% - 55px);
  }

  .ant-modal-body{
    min-height: 80dvh;
    max-height: 100dvh;
    height: 80dvh;
  }
  
  .ant-modal-body {
    overflow-y: auto;
  }

  .modalTitle{
    width: 60%;
    position: absolute;
    top: 1.5%;
    left: 4%;
  }

  .modalTitle p{
    margin: 0;
    font-size: 1.2rem;
    font-weight: 500;
  }
  

  .contentMain{
    height: 100dvh;
    margin-top: 20%;
  }


  .fieldContainer {
    width: 95%;
    display: flex;
    flex-direction: column;
    gap: 2%;
  }
    
  .fieldLabel{
    margin-top: 3%;
  }
  .fieldLabel p {
    margin: 0;
    font-size: 1rem;
    font-weight: 500;
  }
  .fieldInput {
    width: 100%;
    margin-top: 2%;
  }
  
  .fieldInput input{
      background-color: #FFF9F1;
      outline: none;
      border: none;
      width: 100%;
      height: 3rem;
      border-radius: 8px;
      padding-left: 3%;
      font-size: 0.8rem;
  }

  .fieldInput textarea{
    background-color: #FFF9F1;
    outline: none;
    border: none;
    width: 100%;
    height: 5rem;
    border-radius: 8px;
    resize: none;
    padding-left: 3%;
  }

  .modalButton{
    width: 100%;
    display: flex;
    padding: 0 2%;
    margin-top: 10%;
    gap: 8%;
  }

  .modalButton button {
    font-weight: 400;
    font-size: 0.7rem;
    color: #ffffff;
    background: #ff9914;
    border: none;
    width: 30%;
    padding: 0 3%;
    height: 5.5dvh;
    border-radius: 18px;
  }
  
  .modalButton button:first-child {
    color: #000000;
    background-color: #ffffff;
    border: 1px solid #95a1bb;
  }


  @media screen and (max-width:767px) {
    .modal{
      width: 100dvw !important;
    }
  }