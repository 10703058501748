.main {
  width: 100%;
  font-family: Poppins;
  padding-bottom: 2%;
}

.detailMainContainer {
  width: 98%;
  margin-left: 1%;
  display: flex;
  flex-direction: column;
  padding-bottom: 4%;
}

.rowContainer {
  width: 100%;
  display: flex;
  font-family: Roboto;
}

.detailContainer {
  width: 50%;
  position: relative;
}

.detailsHeading {
  width: 100%;
  padding: 1rem;
}
.details {
  width: 100%;
  display: flex;
}
.profileImage {
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profileImageCircle {
  width: 5.5rem;
  height: 5.5rem;
  border-radius: 50%;
  overflow: hidden;
}

.profileImageCircle img {
  object-position: center;
  width: 100%;
  height: 100%;
}
.detailsContent {
  width: 80%;
  display: flex;
  flex-direction: column;
  padding-left: 4%;
}
.singleDetail {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 1% 0;
}
.singleDetailLeft {
  width: 35%;
}
.singleDetailRight {
  width: 65%;
}

.detailsHeading li {
  font-size: 0.8rem;
  font-weight: 600;
}

.singleDetail p {
  font-size: 0.8rem;
  margin: 0;
  padding-top: 2%;
}

.singleDetailLeft p {
  font-weight: 500;
}

.documentImageContainer {
  height: 25dvh;
  width: 100%;
}

.singleDocumentContainer {
  display: flex;
  height: 100%;
  gap: 3%;
}

.documentSide {
  width: 35%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.documentSide p {
  font-size: 0.8rem;
  font-weight: 600;
}
.documentImage {
  width: 65%;
}
.documentImage img {
  width: 20dvw;
  height: 20dvh;
  object-fit:fill;
}

@media (max-width:767px){
  .profileImageCircle {
    width: 70px;
    height: 70px;
  }
  .rowContainer{
    display: block;
  }
  .detailContainer {
    width: 100%;
  }
  .detailsHeading ul{
    padding-left: 0px;
  }
}