.pageHeader {
  display: flex;
  justify-content: space-between;
  padding: 2% 2% 3% 0 !important;
  background-color: #fcfcfc;
}
.pageHeaderLeft {
  width: 60%;
}

.pageHeaderLeft p {
  font-weight: 600;
  font-size: 1.1rem;
  padding-left: 1.5%;
  width: 100%;
  cursor: pointer;
}

.pageHeaderRight {
  width: 40%;
  display: flex;
  justify-content: flex-end;
}

.pageHeaderRight button {
  font-weight: 400;
  font-size: 0.7rem;
  color: #ffffff;
  background: linear-gradient(95.2deg, #ff9914 0%, #ff4c04 54.17%);
  border: none;
  padding: 0 3.5%;
  height: 5.5dvh;
  border-radius: 8px;
  min-width: 28%;
}

.activePageStyle {
  color: #ff9914;
  margin-left: 1.5%;
}

.subHeadingColor {
  color: #ff9914;
}

@media screen and (max-width: 767px) {
  .pageHeader {
    background-color: #fcfcfc;
    width: 100%;
    flex-direction: column;
    padding: 8% 2% 3% 2% !important;
    align-items: flex-start;
    gap: 2svh;
    margin-bottom: 2%;
  }
  .pageHeaderLeft {
    width: 100%;
  }
  .pageHeaderRight {
    margin-left: 0%;
    width: 100%;
    padding: 0;
    justify-content: flex-start;
  }

  .pageHeaderLeft p {
    margin: 0;
    font-weight: 600;
    font-size: 1.1rem;
    padding: 0px 2%;
    margin-top: 1%;
  }

  .pageHeaderBreadcrumb {
    margin-top: 5%;
  }

  .pageHeaderRight button {
    width: 48% !important;
    height: 6.5dvh !important;
  }
}
