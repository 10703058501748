.main {
    width: 100%;
    font-family: Poppins;
    padding-bottom: 2%;
  }

  .contentContainer {
    width: 97.5%;
    box-shadow: 0px 1px 8px 0px #00000026;
    margin-left: 1%;
    padding: 1%;
    border-radius: 12px;
    font-family: Poppins;
  }
  
  .abhiruchiType{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2%;
  }

  .abhiruchiHeading{
    width:20%;
  }

  .abhiruchiHeading p{
    font-size: 0.9rem;
    font-weight: 600;
    margin: 0;
  }
  
  .abhiruchiSelect{
    width: 25%;
  }


  .radioItems{
    margin-top: 2%;
    padding-left: 1%;
    margin-bottom: 2%;
    width: 100%;
    display: flex;
    flex-direction: row;
    
  }
  .radioDiv {
    width: 18%;
    display: flex;
    gap: 8%;
  }
  
  .radioDiv > label {
    font-size: 0.8rem;
    color: #707070;
    font-weight: 500;
  }
  
  .radioDiv .activeRadio {
    color: #ff9914 !important;
  }
  
  .radioStyle {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 2px solid #ff9914;
    position: relative;
  }
  
  .radioStyle::before {
    content: "";
    width: 10px;
    height: 10px;
    background-color: transparent;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .radioStyle:checked::before {
    background-color: #ff9914;
  }