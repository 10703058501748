@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Style the inner text color and background for active and inactive states */
.ant-switch-inner {
  color: #fff; /* Text color inside switch */
}

/* Optional handle styling adjustments */
.ant-switch-handle::before {
  background-color: black;
  /* top: 1px !important; */
  height: 0.7rem !important;
  width: 0.7rem !important;
  border-radius: 50%; /* Optional for a rounded handle */
  transition: background-color 0.3s ease; /* Smooth color transition */
}

/* Style for the handle in the checked state */
.ant-switch-checked .ant-switch-handle::before {
  background-color: white !important;
}

/* Inactive (unchecked) background color */
.ant-switch {
  background-color: grey !important; /* Grey background for inactive */
  padding: 1px !important;
}

/* For the checked (active) switch */
.ant-switch-checked {
  background-color: green !important; /* Green background for active */
}



.ant-modal-footer {
  display: none !important;
}

.ant-modal-root .ant-modal {
  max-width: calc(100vw - 0px) !important;
  margin: 0px auto !important;
}

.progress {
  --bs-progress-bg: none !important;
}

.stepperDiv .defaultBtn {
  display: none !important;
}

.fieldInputMulti .css-1hb7zxy-IndicatorsContainer {
  align-items: baseline !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


/* Hide the spinner buttons in Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  padding:0 10px !important;
}

/* Hide the default checkbox appearance */
input[type='checkbox'] {
  -webkit-appearance: none; 
  -moz-appearance: none; 
  appearance: none; 
  width: 1rem;
  height: 1rem;
  border: 2px solid #ff9914; 
  border-radius: 4px;
  outline: none;
  position: relative;
  cursor: pointer;
  background-color: white; 
}

/* Style the checkbox when checked */
input[type='checkbox']:checked::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
  width: 8px;
  height: 4px;
  border: 2px solid white; 
  border-top: none;
  border-right: none;
}

/* Additional styling when the checkbox is checked */
input[type='checkbox']:checked {
  background-color: #ff9914; 
  border-color: #ff9914; 
}

input[type="text"]{
  padding:5px 10px !important;
}

textarea{
  padding:0 10px !important;
}


@media (max-width:767px){
  .ant-modal-root .ant-modal {
    max-width: 100% !important;
    margin: 0px auto !important;
    width: 100% !important;
}
.log-1 {
  height: 500px !important;
}
.d-flex.carousel-comp,#carouselExample{
  display: none!important;
}
  .userMgmt_main__EV9jP,.panditProfileManagement_main__nbfER{
    margin-top: 40px!important;
  }
  .panditProfileManagement_pageHeader__m8RCm{
    padding-top: 10px!important;
  }
    .navbar_showMobileMenu__AqWRx {
        background-color: #fff!important;
        z-index: 1;
    }
    .style_actionButton__bUOHC,.panditProfileManagement_actionButton__iPXXZ{
      width: 100%!important;
    }
    .panditKYCMgmt_searchKYC__ujHYr input {
      width: 100%!important;
  }
  .navbar_navbarHeader__OmoBh{
    padding: 2% 15px 2% 2%;
    justify-content: space-between;
  }
}