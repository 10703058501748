.main {
  width: 100%;
  font-family: Poppins;
  padding-bottom: 2%;
}

.pageHeader {
  display: flex;
  justify-content: space-between;
  padding: 2% 2% 3% 0 !important;
  background-color: #fcfcfc;
}

.pageHeader p {
  font-weight: 600;
  font-size: 1.1rem;
  padding-left: 0.5%;
  width: 50%;
}

.activePageStyle {
  color: #ff9914;
  margin-left: 1.5%;
}
.pageHeader button {
  font-weight: 400;
  font-size: 0.7rem;
  color: #ffffff;
  background: linear-gradient(95.2deg, #ff9914 0%, #ff4c04 54.17%);
  border: none;
  padding: 0 3%;
  height: 5.5dvh;
  border-radius: 8px;
}

.contentContainer {
  width: 97.5%;
  box-shadow: 0px 1px 8px 0px #00000026;
  margin-left: 1%;
  padding: 1%;
  border-radius: 12px;
  font-family: Poppins;
}
.menuContainer {
  display: flex;
  gap: 1%;
  padding: 1% 2%;
  font-family: Poppins;
}
.singleMenu {
  width: 12%;
  text-align: center;
  padding: 0.75% 0 0 0;
}
.singleMenu p {
  width: 100%;
  margin: 0;
  font-size: 0.9rem;
  color: #000000;
  cursor: pointer;
  text-decoration: none;
  position: relative;
}

.singleMenu p::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 100%;
  height: 1px;
  background-color: #999fa9;
}

.activeMenu {
  background-color: #ff991440;
}

.activeMenu p {
  color: #ff9914;
  font-family: Poppins;
  font-size: 0.8rem;
  font-weight: 600;
}

.activeMenu p::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -3px;
  width: 100%;
  height: 1px;
  background-color: #ff9914;
}

.selectContainer {
  display: flex;
  gap: 2%;
  margin-left: 1%;
  margin-top: 1.5%;
}

.singleSelect {
  width: 42%;
}

.activePage {
  margin-top: 3%;
}

.tableContainer {
  width: 97%;
  border-radius: 8px;
}

table {
  width: 98%;
  border-collapse: collapse;
  margin-top: 0% !important;
  table-layout: fixed;
  margin-left: 1%;
}

th,
td {
  text-align: center !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 0.8rem;
}

thead tr {
  box-shadow: 0px 0px 4px 0px #ff99144d !important;
  border-radius: 8px;
}

tbody tr {
  box-shadow: 0px 0px 2px 0px #999fa980;
  border-radius: 8px;
}

tr {
  margin-bottom: 10px !important;
}

td {
  width: 25%;
  max-width: 25%;
  padding: 1.5%;
}

th {
  padding: 1%;
  font-size: 0.8rem;
  font-weight: 500;
  letter-spacing: 0.4px;
  color: #ff9914;
  position: relative;
}

th:not(:last-child)::after {
  content: "";
  position: absolute;
  top: 25%;
  right: 0px;
  bottom: 0;
  width: 2px;
  height: 3dvh;
  background-color: #e6e6e6 !important;
}

.footer {
  width: 98%;
  margin-left: 1%;
  display: flex;
  justify-content: space-between;
  margin-top: 2%;
}
.action {
  display: flex;
  width: 50%;
  align-items: center;
  padding-bottom: 2%;
  gap: 2%;
}

.actionButton {
  width: 20%;
  text-align: center;
  padding: 1.5%;
  border-radius: 22px;
  border: 1px solid #999fa9;
  font-size: 0.8rem;
  transition: all 0.3s ease;
  cursor: pointer;
}

.activeButton {
  background-color: #ff9914;
  color: #ffffff;
  font-size: 0.8rem;
  border: none !important;
}

.page {
  width: 50%;
  text-align: right;
}

.page span {
  font-size: 0.8rem;
}

.page span:first-child {
  font-size: 0.8rem;
  padding: 0.3% 1.6%;
  border: 1px solid #00054f26;
  border-radius: 5px;
}

.tableviewDetails {
  color: #ff9914;
  text-decoration-line: underline;
  cursor: pointer;
}



@media screen and (max-width: 767px) {
  .pageHeader {
    width: 100%;
    flex-direction: column;
    padding: 8% 2% 3% 2% !important;
    align-items: flex-start;
    gap: 2svh;
  }

  .pageHeader p {
    width: 100%;
    font-size: 1.1rem;
    margin-bottom: 0;
  }

  .pageHeader button {
    padding: 1% 4%;
  }

  .menuContainer {
    width: 100%;
    display: flex;
    gap: 5%;
    padding: 1% 0%;
    font-family: Poppins;
    justify-content: center;
  }
  .singleMenu {
    width: 45%;
    padding: 2% 0 0 0;
    margin-top: 2%;
  }

  .tableMain{
    width: 100%;
    overflow-x: auto !important;
  }
  table {
    width: 180dvw !important;
    border-collapse: collapse;
    margin-top: 5%;
    table-layout:auto !important;
    margin-left: 1%;

  }

  td {
    width: 20dvw !important;
    max-width: 30dvw;
    height: 8dvh;
    padding: 0% !important;
  }

  .actionButton {
    width: 100%;
    text-align: center;
    padding: 3%;
    border-radius: 15px;
    border: 1px solid #999fa9;
    font-size: 0.7rem;
  }

  .selectContainer {
    display: flex;
    gap: 1%;
    margin-left: 0%;
    margin-top: 1.5%;
  }
  
  .singleSelect {
    width: 49%;
  }
}
