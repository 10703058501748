.main {
  width: 100%;
  margin-top: 4%;
}

.detailContainer {
  width: 100%;
}

.secondDetail {
  margin-top: 2%;
}
.detailsHeading {
  width: 100%;
}

.detailsHeading li {
  font-size: 0.8rem;
  font-weight: 600;
}

.detailsHeading p {
  font-size: 0.8rem;
  font-weight: 500;
}
.details {
  width: 100%;
  display: flex;
  padding: 1%;
}
.profileImage {
  width: 80%;
  height: 16dvh;
}

.profileImageCircle {
  /* width: 12%;
  height: 100%; */
  width: 5.5rem;
  height: 5.5rem;
  aspect-ratio: 1/1;
  border-radius: 50%;
  overflow: hidden;
}

.profileImageCircle img {
  object-position: center;
  width: 100%;
  height: 100%;
}

.videoContainer {
  width: 100%;
  display: flex;
  margin-bottom: 2%;
  /* justify-content: space-between; */
  padding: 0 1%;
}

.videoSection {
  width: 80%;
}
.video {
  width: 40%;
  padding: 1% 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #e5e5e5;
}

.video p {
  font-size: 0.8rem;
}

.selectAction {
  width: 20%;
  display: flex;
  gap: 5%;
}

.seeReason {
  width: 45%;
}

.seeReason p {
  text-align: center !important;
  padding: 5% 1% !important;
}

@media (max-width:767px){
  .profileImageCircle,.profileImage{
    width: 70px;
    height: 70px;
  }
  .selectAction {
    width: 100%;
  }
.seeReason select.form-select{
  height: 35px!important;
}
.video{
  width: 100%;
  display: block;
  background: #fff;
}
.videoSection,.videoSection video {
  width: 100%;
}
}