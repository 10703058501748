.main{
    margin-top: 4%;
}

.singleDetailContainer{
    width: 100%;
    display: flex;
    font-family: Roboto;
}
.detailContainer{
    width: 50%;
}
.detailsHeading{
    width: 100%;
}
.details{
    width: 100%;
    display: flex;
}
.profileImage{
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.profileImageCircle{
    width: 90%;
    height: 100%;
}
.profileImageCircle img {
    width: 90%;
    height: 100%;
    object-fit: contain;

}
.detailsContent{
    width: 80%;
    display: flex;
    flex-direction: column;
    padding-left: 4%;
}
.singleDetail{
    width: 100%;
    display: flex;
}
.singleDetailLeft{
    width: 35%;
}
.singleDetailRight{
    width: 65%;
}

.detailsHeading ul {
    margin: 0;
}

.detailsHeading li{
    font-size: 0.8rem;
    font-weight: 600;
}
.checkbox {
    display: flex;
    /* justify-content: center; */
    align-items: center;
}


.detailsHeading input {
    margin-left: 2%;
}

.detailsHeading span{
    font-size: 0.7rem; 
    padding-left: 1.5%;
}
.detailsHeading p{
    color: #FF9914;
    font-size: 0.8rem;
    margin: 0;
    padding: 1% 6%;
}

.singleDetail p{
    font-size: 0.8rem;
    margin: 0;
    padding-top: 2%;
}

.singleDetailLeft p{
    font-weight: 500;
}

@media (max-width:767px){
    .singleDetailContainer{
        display: block;
    }
    .detailContainer,.detailsContent{
        width: 100%;
    }
    .detailsHeading p{
        padding: 1% 4%;
    }
    .checkbox {
        margin: 5px 0px;
    }
    .details{
        padding-left: 0px!important;
    }
}