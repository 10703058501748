.pageHeaderTwo {
  display: flex;
  justify-content: space-between;
  padding: 2% 2% 3% 0 !important;
  background-color: #fcfcfc;
}

.pageHeaderTwoLeft {
  width: 60%;
}

.pageHeaderTwoRight {
  width: 40%;
  display: flex;
  justify-content: flex-end;
  gap: 2%;
}
.pageHeaderTwoLeft p {
  font-weight: 600;
  font-size: 1.1rem;
  padding-left: 1.5%;
  width: 100%;
  cursor: pointer;
}

.activePageStyle {
  color: #ff9914;
  margin-left: 1.5%;
}
.pageHeaderTwoRight button {
  font-weight: 400;
  font-size: 0.7rem;
  color: #ffffff;
  background: #ff9914;
  border: none;
  width: 22%;
  padding: 0 2%;
  height: 5.5dvh;
  border-radius: 18px;
}

.pageHeaderTwoRight button:first-child {
  color: #000000;
  background-color: #ffffff;
  border: 1px solid #95a1bb;
}

@media screen and (max-width: 767px) {
  .pageHeaderTwo {
    background-color: #fcfcfc;
    width: 100%;
    flex-direction: column;
    padding: 8% 2% 3% 2% !important;
    align-items: flex-start;
    gap: 2svh;
    margin-bottom: 2%;
  }
  .pageHeaderTwoLeft{
    width: 100%;
  }
  .pageHeaderTwoRight{
    margin-left: 0%;
    width: 100%;
    padding: 0;
    justify-content: flex-start;
  }
  
  .pageHeaderTwoLeft p {
    margin: 0;
    font-weight: 600;
    font-size: 1.1rem;
    padding: 2%;
    margin-top: 1%;
  }

  .pageHeaderTwoBreadcrumb {
    margin-top: 5%;
  }

  .btnSaveDiscard {
    width: 48% !important;
    height: 6.5dvh !important;
  }

}
