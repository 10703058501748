/* General Layout */
.profileDetails {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  padding: 12px;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.15);
  margin: 0 1rem;
  min-height: 75dvh;
}

/* Card Heading */
.cardHeading {
  font-size: 0.9rem;
  font-weight: 700;
}

/* Left, Right Panels */
.singleCardLeft, 
.singleCardRight {
  margin-top: 5dvh;
  background-color: #fff;
  border-radius: 12px;
  padding: 15px;
  box-shadow: 0 4px 12px rgba(125, 110, 110, 0.1);
  border: 1px solid rgb(233 220 220);
  height: max-content;
  min-height: 60dvh;
}

/* Category Title */
.categoryTitle {
  color: #f57c00;
  font-weight: 600;
  font-size: 16px;
  margin: 20px 0 10px;
}



/* Service Temple - Right Panel */
.serviceList {
  display: grid;
  grid-template-columns: 1fr; /* One card per row by default */
  gap: 15px;
}

.serviceItem {
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 5px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid rgb(233 220 220);
  width: 100%;
}

.serviceIcon {
  font-size: 40px;
  color: #007bff;
  margin-right: 10px;
  height: 90%;
  width: 15%;
}

.serviceInfo h4 {
  margin: 0;
  font-size: 13px;
  font-weight: 550;
}

.serviceInfo p {
  margin: 0;
  font-size: 10px;
  color: #666;
}

/* Location Icon */
.locationIcon {
  margin-right: 5px;
  font-size: 14px;
  color: #666;
}


.poojaContainer {
  margin-top: 10px;
}

.poojaGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(175px, 1fr));
  gap: 10px;
  /* background-color: red; */
 
}

.poojaItem {
  background: rgba(244, 244, 244, 1);

  padding: 0.5rem;
  /* height: 70px !important; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  border-radius: 4px;

}

.poojaItemImage {
  width: 55px;
  height: 55px;
  object-fit: cover;
  border-radius: 30px;
}

.poojaItemText {
  margin: 0;
  font-weight: 500;
  

}
.categoryTitle{
  color: #f57c00;
  font-weight: 600;
  font-size: 16px;
  margin: 20px 0 10px;
}

.noData{
  margin: auto;
  text-align: center;
  margin-top: 10dvh;
  font-size: 0.8rem;
}
/* Responsive adjustments for tablets and larger screens */
@media (min-width: 768px) {
  .profileDetails {
    grid-template-columns: 1fr 1.2fr;
  }

  .serviceList {
    grid-template-columns: 1fr 1fr; /* Two cards per row */
  }
}

@media (max-width: 768px) {
  .profileDetails {
    padding: 10px;
    margin: 0;
  }
  .poojaGrid{
    grid-template-columns: 1fr; /* Two cards per row */

  }
}


