.main {
    width: 100%;
    font-family: Poppins;
    padding-bottom: 2%;
  }
  
  .pageHeader {
    display: flex;
    justify-content: space-between;
    padding: 2% 2% 3% 0 !important;
    background-color: #fcfcfc;
  }
  
  .pageHeader p {
    font-weight: 600;
    font-size: 1.1rem;
    padding-left: 0.5%;
    width: 50%;
  }
  
  .activePageStyle {
    color: #ff9914;
    margin-left: 1.5%;
  }
  .pageHeader button {
    font-weight: 400;
    font-size: 0.7rem;
    color: #000000;
    background: #ffffff;
    border: 1px solid grey;
    padding: 0 3%;
    height: 5.5dvh;
    border-radius: 18px;
  }
  
  .contentContainer{
      width: 97.5%;
      box-shadow: 0px 1px 8px 0px #00000026;
      margin-left: 1%;
      padding: 1%;
      border-radius: 12px;
      font-family: Poppins;
  }
  .menuContainer {
      display: flex;
      gap: 1%;
      padding: 1% 2%;
      font-family: Poppins;
    }
    .singleMenu{
      width: 12%;
      text-align: center;
      padding: 0.5% 0 0 0;
    }
    .singleMenu p {
      width: 100%;
      margin: 0;
      font-size: 0.9rem;
      color: #000000;
      cursor: pointer;
      text-decoration: none;
      position: relative;
    }
  
    .singleMenu p::after  {
      content: "";
      position: absolute;
      left: 0;
      bottom: -1px; 
      width: 100%;
      height: 1px;  
      background-color: #999FA9;
    }
  
    .activeMenu{
      background-color:#FF991440;
    }
    
    .activeMenu p{
      color:#FF9914;
      font-family: Poppins;
      font-size: 0.8rem;
      font-weight: 600;  
    }
  
    .activeMenu p::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: -3px; 
      width: 100%;
      height: 1px;  
      background-color: #FF9914;
    }
  
    .activePage{
      margin-top: 3%;
    }
  
  .payoutTableContainer {
    width: 97%;
    border-radius: 8px;
  }
  
  .payoutTableContainer table {
    width: 98%;
    border-collapse: collapse;
    margin-top: 0% !important;
    table-layout: fixed;
    margin-left: 1%;
  }
  
  .payoutTableContainer th,
  td {
    text-align: center !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 0.8rem;
  }
  
  .payoutTableContainer thead tr {
    box-shadow: 0px 0px 4px 0px #ff99144d !important;
    border-radius: 8px;
  }
  
  .payoutTableContainer tbody tr {
    box-shadow: 0px 0px 2px 0px #999fa980;
    border-radius: 8px;
  }
  
  .payoutTableContainer tr {
    margin-bottom: 10px !important;
  }
  
  .payoutTableContainer td {
    width: 25%;
    max-width: 25%;
    padding: 1.5%;
  }
  
  .payoutTableContainer th {
    padding: 1%;
    font-size: 0.8rem;
    font-weight: 500;
    letter-spacing: 0.4px;
    color: #ff9914;
    position: relative;
  }
  
  .payoutTableContainer th:not(:last-child)::after {
    content: "";
    position: absolute;
    top: 25%;
    right: 0px;
    bottom: 0;
    width: 2px;
    height: 3dvh;
    background-color: #E6E6E6 !important;
  }
  
  .footer {
    width: 98%;
    margin-left: 1%;
    display: flex;
    justify-content: space-between;
    margin-top: 2%;
  }
  .action {
    display: flex;
    width: 50%;
    align-items: center;
    padding-bottom: 2%;
    gap: 2%;
  }
  
  .actionButton {
    width: 20%;
    text-align: center;
    padding: 1.5%;
    border-radius: 22px;
    border: 1px solid #999fa9;
    font-size: 0.8rem;
    transition: all 0.3s ease; 
  cursor: pointer;
  }
  
  .activeButton {
    background-color: #ff9914;
    color: #ffffff;
    font-size: 0.8rem;
    border: none !important;
  }
  
  .page {
    width: 50%;
    text-align: right;
  }
  
  .page span {
    font-size: 0.8rem;
  }
  
  .page span:first-child {
    font-size: 0.8rem;
    padding: 0.3% 1.6%;
    border: 1px solid #00054f26;
    border-radius: 5px;
  }
  

  @media screen and (max-width: 767px) {
    .pageHeader {
      flex-direction: column;
      padding: 6% 2% !important;
      width: 100%;
    }
    
    .pageHeader p{
      width: 100%;
    }
    .pageHeader button {
      width: 43%;
      padding: 0 3.5%;
      height: 6.5dvh;
    }  
  
    .contentContainer {
      padding: 3% 1%;
    }

    .singleMenu {
      width: 48%;
      text-align: center;
      padding: 2% 0 0 0;
    }    
    
    .activeMenu {
      background-color: #ff991440;
    }
    
    .activeMenu p {
      color: #FF9914;
      font-family: Poppins;
      font-size: 0.8rem;
      font-weight: 600;
    }    
    .activePage {
      margin-top: 2%;
    }

    .payoutTableContainer{
      width: 100%;
      overflow-x: auto !important;
      padding-top: 3%;
    }

  }