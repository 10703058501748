.main {
  width: 100%;
  padding-bottom: 2%;
}

.pageHeader {
  display: flex;
  justify-content: space-between;
  padding: 2% 2% 3% 0;
  background-color: #fcfcfc;
  font-family: Poppins;
  gap: 2%;
}

.pageHeaderLeft {
  width: 50%;
}

.pageHeaderLeft p {
  font-weight: 600;
  font-size: 1.1rem;
  padding-left: 2%;
}
.pageHeaderRight {
  width: 50%;
  display: flex;
  gap: 2%;
}

.pageHeaderMonth {
  width: 40%;
  display: flex;
  align-items: center;
  background-color: #e3e3e3;
  border-radius: 5px;
}
.pageHeaderMonth p {
  margin: 0;
  padding-left: 10%;
  font-size: 0.8rem;
  font-weight: 600;
}

.filterButton {
  width: 58%;
  display: flex;
  gap: 1%;
}

.filterButton button {
  width: 33%;
  font-weight: 400;
  font-size: 0.7rem;
  color: #000000;
  background-color: #ffffff;
  box-shadow: 0px 1px 4px 0px #00000026;
  border: none;
  padding: 0 2%;
  height: 6.5dvh;
  border-radius: 5px;
}

.cardMain {
  width: 100%;
  display: flex;
  padding: 0 1%;
}

.cardLeftMain {
  width: 65%;
  display: flex;
  gap: 2%;
}

.cardRightMain {
  width: 35%;
  padding-left: 1.5%;
}

.singleCardLeft {
  width: 32%;
  display: flex;
  box-shadow: 0px 1px 8px 0px #00000026;
  padding: 2% 0.5%;
  border-radius: 8px;
}

.cardIcon {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardIcon img {
  width: 90%;
}

.cardContent {
  width: 70%;
  display: flex;
  flex-direction: column;
  padding-left: 5%;
  text-align: center;
  padding: 2.5%;
}

.cardContent p:nth-child(1) {
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0;
  padding-top: 5%;
}

.cardContent p:nth-child(2) {
  font-size: 0.8rem;
  font-weight: 500;
  margin: 0;
}
.cardContent p:nth-child(3) {
  font-size: 0.8rem;
  font-weight: 500;
  margin: 0;
}

.cardRightBox {
  width: 98%;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 8px 0px #00000026;
  border-radius: 8px;
  padding: 1%;
  gap: 0.5%;
}

.cardRightHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 2% 2%;
}
.cardRightHeader p:nth-child(1) {
  font-size: 0.7rem;
  font-weight: 400;
  color: #000000;
  margin: 0;
}
.cardRightHeader p:nth-child(2) {
  font-size: 0.7rem;
  font-weight: 400;
  color: #ff9914;
  margin: 0;
}

.cardRightContainer {
  width: 100%;
  display: flex;
  gap: 1%;
  padding: 2% 0;
}

.singleCardRight {
  width: 32%;
  display: flex;
  background: #f6f3ff;
  border-radius: 8px;
}

.cardIconRight {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardIconRight img {
  width: 70%;
}

.cardContentRight {
  width: 70%;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding-top: 20%;
  padding: 0% 1%;
}

.cardContentRight p:nth-child(1) {
  font-size: 1rem;
  font-weight: 600;
  margin: 0;
  padding-top: 8%;
}

.cardContentRight p:nth-child(2) {
  font-size: 0.7rem;
}

.selectContainer {
  display: flex;
  gap: 2%;
  margin-left: 1%;
  margin-top: 1.5%;
}

.selectContainer p {
  font-size: 0.8rem;
  font-weight: 500;
  margin: 0;
  padding-top: 6%;
  padding-bottom: 0.5%;
}

.singleSelect {
  width: 23%;
}

.inputDate {
  width: 23%;
}

.inputDate input {
  width: 100%;
  padding: 3%;
  margin-top: 1%;
  border: none;
  outline: none;
  background-color: #fff9f1;
  border-radius: 8px;
}

.tableContainer {
  width: 97%;
  margin-left: 1%;
  margin-top: 2%;
  box-shadow: 0px 1px 8px 0px #00000026;
  border-radius: 8px;
}

.filterExport {
  width: 100%;
  display: flex;
  gap: 2%;
  align-items: center;
  justify-content: right;
  padding: 2% 0 1% 0;
}

.filterExport p {
  width: 50%;
  margin: 0;
}

.search {
  width: 13%;
}

.search input {
  width: 100%;
  background-color: #fff9f1;
  border-radius: 8px;
  background-image: url(../../assets/images/searchIcon.png);
  background-repeat: no-repeat;
  background-size: 10%;
  background-position-x: 90%;
  background-position-y: 45%;
  padding: 3%;
  font-size: 0.8rem;
  outline: none;
  border: none;
}

.search input:focus {
  background-image: none;
}

.export {
  border: 1px solid #e6e6e6;
  border-radius: 15px;
  width: 20%;
}

.export p {
  width: 100%;
  margin: 0;
  padding: 2.5%;
  font-size: 0.7rem;
  font-weight: 600;
  text-align: center;
}

.export > p > a {
  text-decoration: none;
  color: #000000;
}

.filterDiv {
  border: 1px solid #e6e6e6;
  border-radius: 15px;
  width: 8%;
}

.filterDiv p {
  width: 100%;
  margin: 0;
  padding: 5% 0;
  font-size: 0.7rem;
  font-weight: 600;
  text-align: center;
  background-image: url(../../assets/images/filter.png);
  background-size: 14px;
  background-position-x: 18%;
  background-position-y: 50%;
  background-repeat: no-repeat;
}

.tableSection table {
  width: 98%;
  border-collapse: collapse;
  margin-top: 5%;
  table-layout: fixed;
  margin-left: 1%;
}

.tableSection th,
td {
  text-align: center !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 0.8rem;
}

.tableSection thead tr {
  box-shadow: 0px 0px 4px 0px #ff99144d !important;
  border-radius: 8px;
}

.tableSection tbody tr {
  box-shadow: 0px 0px 2px 0px #999fa980;
  border-radius: 8px;
}

.tableSection tr {
  margin-bottom: 10px !important;
}

.tableSection td {
  width: 25%;
  max-width: 25%;
  padding: 1.5%;
}

.tableSection th {
  padding: 1%;
  font-size: 0.8rem;
  font-weight: 500;
  letter-spacing: 0.4px;
  color: #ff9914;
  position: relative;
}

.tableSection th:not(:last-child)::after {
  content: "";
  position: absolute;
  top: 25%;
  right: 0px;
  bottom: 0;
  width: 2px;
  height: 3dvh;
  background-color: #e6e6e6 !important;
}

.kycViewDetails {
  color: #ff9914;
  text-decoration-line: underline;
  cursor: pointer;
}

.footer {
  width: 98%;
  margin-left: 1%;
  display: flex;
  justify-content: space-between;
  margin-top: 2%;
}
.action {
  display: flex;
  width: 50%;
  align-items: center;
  padding-bottom: 2%;
  gap: 2%;
}

.actionButton {
  width: 20%;
  text-align: center;
  padding: 1.5%;
  border-radius: 22px;
  border: 1px solid #999fa9;
  font-size: 0.8rem;
  cursor: pointer;
  transition: all 0.3s ease;
  cursor: pointer;
  outline: none;
}

.activeButton {
  background-color: #ff9914;
  color: #ffffff;
  font-size: 0.8rem;
  border: none !important;
}

.page {
  width: 50%;
  text-align: right;
}

.page span {
  font-size: 0.8rem;
}

.page span:first-child {
  font-size: 0.8rem;
  padding: 0.3% 1.6%;
  border: 1px solid #00054f26;
  border-radius: 5px;
}

.payButton {
  font-size: 0.7rem !important;
  color: #ffffff;
  font-weight: 500;
  padding: 4% 0;
  width: 50%;
  border-radius: 16px !important;
  background-color: #ff9914 !important;
  border: none !important;
  outline: none !important;
}

.disabledPayButton {
  background-color: #a7a7a7;
  font-size: 0.7rem !important;
  color: #ffffff;
  font-weight: 500;
  padding: 4% 0;
  width: 50%;
  border-radius: 16px !important;
  border: none !important;
  outline: none !important;
}

@media screen and (max-width: 767px) {
  .pageHeader {
    padding: 8% 2% 3% 2%;
    align-items: center;
  }

  .pageHeader p {
    font-size: 0.85rem;
    margin-bottom: 0;
  }

  .cardMain {
    flex-direction: column;
    margin-top: 2%;
  }

  .cardLeftMain {
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 2svh;
  }

  .cardRightMain {
    width: 100%;
    padding-left: 1.5%;
  }

  .singleCardLeft {
    width: 85%;
    padding: 2%;
  }

  .cardIcon {
    width: 30%;
  }

  .cardIcon img {
    width: 60%;
  }

  .cardContent p:nth-child(1) {
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0;
    padding-top: 5%;
  }

  .cardContent p:nth-child(2) {
    font-size: 0.8rem;
    font-weight: 500;
    margin: 0;
  }
  .cardContent p:nth-child(3) {
    font-size: 0.8rem;
    font-weight: 500;
    margin: 0;
  }

  .cardRightBox {
    margin-top: 2%;
  }

  .cardRightContainer {
    flex-direction: column;
    align-items: center;
    gap: 2svh;
  }

  .singleCardRight {
    width: 85%;
  }

  .cardIconRight img {
    width: 50%;
  }

  .selectContainer {
    flex-direction: column;
    gap: 2%;
  }

  .singleSelect {
    width: 98%;
  }

  .inputDate {
    width: 98%;
  }

  .tableContainer {
    width: 97%;
    margin-left: 1%;
    margin-top: 2%;
    box-shadow: 0px 1px 8px 0px #00000026;
    border-radius: 8px;
  }

  .filterHeader {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 2svh;
  }

  label {
    font-size: 0.7rem;
    color: #707070;
    font-weight: 500;
  }

  .filterExport {
    gap: 1.5dvh;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 3% 0 1% 0;
  }

  .filterExport p {
    width: 100%;
    margin: 0;
  }

  .search {
    width: 100%;
  }

  .search input {
    background-size: 4%;
    height: 6.5dvh;
    padding: 3%;
  }

  .export {
    width: 100%;
  }

  .filterDiv {
    width: 50%;
  }

  .filterDiv p {
    background-size: 10%;
    background-position-x: 28%;
  }

  .tableSection {
    width: 100%;
    overflow-x: auto !important;
  }

   .tableSection table {
    width: 300dvw !important;
    border-collapse: collapse;
    margin-top: 5%;
    table-layout: auto !important;
    margin-left: 1%;
  }

  .tableSection td {
    width: 20dvw !important;
    max-width: 30dvw;
    height: 8dvh;
    padding: 0% !important;
  }

}
