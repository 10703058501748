.main {
  width: 100%;
  padding: 0 1%;
}

.childKycManagementTable {
  width: 97%;
  margin-left: 1%;
  margin-top: 2%;
  box-shadow: 0px 1px 8px 0px #00000026;
  border-radius: 8px;
}

.filterHeader {
  display: flex;
  width: 100%;
}

.radioFilter {
  width: 70%;
  display: flex;
  gap: 5%;
  align-items: center;
  padding: 2%;
}

.radioOne {
  width: 10%;
  display: flex;
  gap: 26%;
}
.radioTwo {
  width: 16%;
  display: flex;
  gap: 14%;
}

.radioFilter label {
  font-size: 0.8rem;
  color: #707070;
  font-weight: 500;
}

.activeRadio {
  color: #ff9914;
}

.radioStyle {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 2px solid #ff9914;
  position: relative;
}

.radioStyle::before {
  content: "";
  width: 10px;
  height: 10px;
  background-color: transparent;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.radioStyle:checked::before {
  background-color: #ff9914;
}

.filterExport {
  width: 30%;
  display: flex;
  gap: 2%;
  align-items: center;
  padding: 1%;
}

.export {
  border: 1px solid #e6e6e6;
  border-radius: 15px;
  width: 64%;
  cursor: pointer;
}

.export p {
  margin: 0;
  padding: 2.5%;
  font-size: 0.7rem;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
}

.export > p > a {
  text-decoration: none;
  color: #000000;
}

.filterDiv {
  border: 1px solid #e6e6e6;
  border-radius: 15px;
  width: 30%;
}

.filterDiv p {
  margin: 0;
  padding: 5% 0;
  font-size: 0.7rem;
  font-weight: 600;
  text-align: center;
  background-image: url(../../assets/images/filter.png);
  background-size: 13px;
  background-position-x: 16%;
  background-position-y: 50%;
  background-repeat: no-repeat;
  cursor: pointer;
}

.search {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 0 2% 1% 0;
}

.search input {
  width: 20%;
  background-color: #fff9f1;
  border-radius: 15px;
  background-image: url(../../assets/images/searchIcon.png);
  background-repeat: no-repeat;
  background-size: 5%;
  background-position-x: 90%;
  background-position-y: 45%;
  padding: 1%;
  font-size: 0.8rem;
  outline: none;
  border: none;
}

.search input:focus {
  background-image: none;
}

.contentContainer {
  width: 97.5%;
  box-shadow: 0px 1px 8px 0px #00000026;
  margin-left: 1%;
  padding: 1%;
  border-radius: 12px;
  font-family: Poppins;
  padding-top: 2%;
}

.kycViewDetails {
  color: #ff9914;
  text-decoration-line: underline;
  cursor: pointer;
}

.statusText {
  width: 7dvw;
  margin: 0 auto;
  padding: 0.5dvh 0.5dvw;
  font-size: 0.7rem;
  font-weight: 600;
  border-radius: 12px;
}

@media screen and (max-width: 767px) {
  .filterHeader {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 2svh;
  }

  .radioFilter {
    width: 100%;
    flex-direction: column;
    gap: 2svh;
  }
  .radioOne {
    width: 100%;
    gap: 5%;
    padding-left: 20%;
  }
  .radioTwo {
    width: 100%;
    gap: 5%;
    padding-left: 20%;
  }
  .radioThree {
    width: 100%;
    gap: 5%;
    padding-left: 20%;
  }
  .radioFour {
    width: 100%;
    gap: 5%;
    padding-left: 20%;
  }

  .radiolabel {
    font-size: 0.7rem;
    color: #707070;
    font-weight: 500;
  }

  .activeRadio {
    color: #ff9914;
  }

  .radioStyle {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 2px solid #ff9914;
    position: relative;
  }

  .radioStyle::before {
    content: "";
    width: 10px;
    height: 10px;
    background-color: transparent;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .radioStyle:checked::before {
    background-color: #ff9914;
  }

  .filterExport {
    width: 100%;
    gap: 2dvh;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 1% 2%;
  }

  .filterExport p {
    width: 100%;
    margin: 0;
  }

  .searchKYC {
    width: 100%;
    margin-top: 2dvh;
    padding: 2%;
  }

  .searchKYC input {
    background-size: 4%;
    height: 6.5dvh;
    padding: 3%;
  }

  .export {
    width: 100%;
  }

  .filterDiv {
    width: 50%;
  }

  .filterDiv p {
    background-size: 10%;
    background-position-x: 28%;
  }
  .search input{
    width: 100%;
  background-repeat: 23px 24px;
  }
  .search {
    margin: 5px 5px 10px 5px;
  }
  .statusText{
    width: 100%;
    display: inline;
    padding: 6px 15px;
  }
}
