.tableCompContainer {
  width: 99%;
  border-radius: 8px;
  margin-top: 3%;
  overflow-x: auto;
  padding: 1%;
}

.tableCompContainer > table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 0% !important;
  /* table-layout: auto; */
}

.tableCompContainer th,
td {
  text-align: center !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 0.8rem;
  width: 15%;
}

.tableCompContainer thead tr {
  box-shadow: 0px 0px 4px 0px #ff99144d !important;
  border-radius: 8px;
}

.tableCompContainer tbody tr {
  box-shadow: 0px 0px 2px 0px #999fa980;
  border-radius: 8px;
}

.tableCompContainer tr {
  margin-bottom: 10px !important;
}

.tableCompContainer td {
  padding: 1.5%;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
}

.tableCompContainer th {
  padding: 1%;
  font-size: 0.8rem;
  font-weight: 500;
  letter-spacing: 0.4px;
  color: #ff9914;
  position: relative;
}

.tableCompContainer th:not(:last-child)::after {
  content: "";
  position: absolute;
  top: 25%;
  right: 0px;
  bottom: 0;
  width: 2px;
  height: 3dvh;
  background-color: #e6e6e6 !important;
}

.tableImage {
  width: 2rem !important;
  height: 2rem !important;
}

.footer {
  width: 98%;
  margin-left: 1%;
  display: flex;
  justify-content: space-between;
  margin-top: 2%;
}
.action {
  display: flex;
  width: 50%;
  align-items: center;
  padding-bottom: 2%;
  gap: 2%;
}

.actionButton {
  width: 20%;
  text-align: center;
  padding: 1.5%;
  border-radius: 22px;
  border: 1px solid #999fa9;
  font-size: 0.8rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.actionTd {
  padding: 0.5rem !important;
}

.actionTd img {
  width: 15px;
  height: 15px;
  margin-right: 15px;
  cursor: pointer;
}

.activeButton {
  background-color: #ff9914;
  color: #ffffff;
  font-size: 0.8rem;
  border: none !important;
}

.page {
  width: 50%;
  text-align: right;
}

.page span {
  font-size: 0.8rem;
}

.page span:first-child {
  font-size: 0.8rem;
  padding: 0.3% 1.6%;
  border: 1px solid #00054f26;
  border-radius: 5px;
}

.tableviewDetails {
  color: #ff9914;
  text-decoration-line: underline;
  cursor: pointer;
}

.viewDetails {
  color: #ff9914;
  text-decoration-line: underline;
  cursor: pointer;
}

.editDetails {
  color: #5b5dfd;
  margin-left: 10%;
  text-decoration-line: underline;
  cursor: pointer;
}

@media screen and (max-width: 767px) {

  .tableCompContainer {
    width: 100%;
    overflow-x: auto !important;
  }
  .tableCompContainer table {
    min-width: 220dvw !important;
    border-collapse: collapse;
    margin-top: 5%;
    margin-left: 1%;
  }

  .tableCompContainer td {
    min-width: 40dvw !important;
    height: 8dvh;
    padding: 0% !important;
  }

  .tableCompContainer td:nth-child(1){
    min-width: 25dvw !important; 
  }
  .tableCompContainer td:nth-child(2){
    min-width:  45dvw !important; 
  }
  .tableCompContainer td:nth-child(3){
    min-width:  45dvw !important; 
  }
  .tableCompContainer td:nth-child(4){
    min-width:  45dvw !important; 
  }
  .tableCompContainer td:nth-child(5){
    min-width:  45dvw !important; 
  }
  .tableCompContainer td:nth-child(6){
    min-width:  45dvw !important; 
  }
  .tableCompContainer td:nth-child(7){
    min-width:  45dvw !important; 
  }
  .tableCompContainer td:nth-child(8){
    min-width:  45dvw !important; 
  }

}
