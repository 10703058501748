.tableMain {
  width: 100%;
  margin-top: 2%;
  border-radius: 8px;
}

.filterExport {
  width: 100%;
  display: flex;
  gap: 2%;
  align-items: center;
  justify-content: center;
  padding: 0 0 1% 0;
}

.filterExport p{
  width: 50%;
  margin: 0;
}

.search {
  width: 13%;
}

.search input {
  width: 100%;
  background-color: #fff9f1;
  border-radius: 8px;
  background-image: url(../../../assets/images/searchIcon.png);
  background-repeat: no-repeat;
  background-size: 10%;
  background-position-x: 90%;
  background-position-y: 45%;
  padding: 3%;
  font-size: 0.8rem;
  outline: none;
  border: none;
}

.search input:focus {
  background-image: none;
}

.export {
  border: 1px solid #e6e6e6;
  border-radius: 15px;
  width: 20%;
}

.export p {
  width: 100%;
  margin: 0;
  padding: 2.5%;
  font-size: 0.7rem;
  font-weight: 600;
  text-align: center;
}

.export > p > a{
  text-decoration: none;
  color: #000000;
}

.filterDiv {
  border: 1px solid #e6e6e6;
  border-radius: 15px;
  width: 8%;
}

.filterDiv p {
  width: 100%;
  margin: 0;
  padding: 5% 0;
  font-size: 0.7rem;
  font-weight: 600;
  text-align: center;
  background-image: url(../../../assets/images/filter.png);
  background-size: 14px;
  background-position-x: 18%;
  background-position-y: 50%;
  background-repeat: no-repeat;
}

.poojaTableMain{
  width: 100%;
  overflow-x: auto !important;
  padding-top: 3%;
}

.poojaTableMain table {
  width: 130%;
  border-collapse: collapse;
  margin-top: 0% !important;
  table-layout: fixed;
  margin-left: 0 !important;
}

.poojaTableMain th,
td {
  text-align: center !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 0.7rem;
  padding: 1% 2%;
}

.poojaTableMain thead tr {
  box-shadow: 0px 0px 4px 0px #ff99144d !important;
  border-radius: 8px;
}

.poojaTableMain tbody tr {
  box-shadow: 0px 0px 2px 0px #999fa980;
  border-radius: 8px;
}

.poojaTableMain tr {
  margin-bottom: 10px !important;
}

.poojaTableMain td {
  width: 25%;
  max-width: 25%;
  padding: 1.5%;
}

.poojaTableMain th {
  padding: 1%;
  font-size: 0.7rem;
  font-weight: 500;
  letter-spacing: 0.4px;
  color: #ff9914;
  position: relative;
}

.poojaTableMain th:not(:last-child)::after {
  content: "";
  position: absolute;
  top: 25%;
  right: 0px;
  bottom: 0;
  width: 2px;
  height: 3dvh;
  background-color: #E6E6E6 !important;
}

.footer {
  width: 98%;
  margin-left: 1%;
  display: flex;
  justify-content: space-between;
  margin-top: 2%;
}
.action {
  display: flex;
  width: 50%;
  align-items: center;
  padding-bottom: 2%;
  gap: 2%;
}

.actionButton {
  width: 20%;
  text-align: center;
  padding: 1.5%;
  border-radius: 22px;
  border: 1px solid #999fa9;
  font-size: 0.8rem;
  transition: all 0.3s ease; 
  cursor: pointer;
}

.activeButton {
  background-color: #ff9914;
  color: #ffffff;
  font-size: 0.8rem;
  border: none !important;
}

.page {
  width: 50%;
  text-align: right;
}

.page span {
  font-size: 0.8rem;
}

.page span:first-child {
  font-size: 0.8rem;
  padding: 0.3% 1.6%;
  border: 1px solid #00054f26;
  border-radius: 5px;
}


.actionTd img{
  width:15px;
  height:15px;
  margin-right:5px;
  cursor: pointer;
}

.viewDetails{
  color: #ff9914;
  text-decoration-line: underline;
  cursor: pointer;
}
.selectContainer {
  display: flex;
  gap: 2%;
  margin-left: 1%;
  margin-top: 1.5%;
  margin-bottom: 4%;
}

.selectContainer p {
  font-size: 0.8rem;
  font-weight: 500;
  margin: 0;
  padding-top: 6%;
  padding-bottom: 0.5%;
}

.singleSelect {
  width: 23%;
}

.completedStyle{
  display: inline-block;
  background-color: #FF9914;
  color: white;
  padding: 6% 8%;
  border-radius: 8px;
}



@media screen and (max-width: 767px) {

  .filterExport {
    flex-direction: column;
    gap: 2svh;
  }

  .filterExport p{
    width: 100%;
    margin: 0;
  }
  
  .search {
    width: 100%;
  }
  
  .search input {
    background-size: 5%;
  }
  
  .search input:focus {
    background-image: none;
  }

  .export {
    width: 100%;
  }
  
  
  .filterDiv {
    border: 1px solid #e6e6e6;
    border-radius: 15px;
    width: 30%;
  }
  
  .poojaTableMain{
    width: 100%;
    overflow-x: auto !important;
  }
  .poojaTableMain table {
    width: 180dvw !important;
    border-collapse: collapse;
    margin-top: 5%;
    table-layout:auto !important;
    margin-left: 1%;

  }

  .poojaTableMain td {
    width: 20dvw !important;
    max-width: 30dvw;
    height: 8dvh;
    padding: 0% !important;
  }

  .actionButton {
    width: 100%;
    text-align: center;
    padding: 3%;
    border-radius: 15px;
    border: 1px solid #999fa9;
    font-size: 0.7rem;
  }
  .singleSelect {
    width: 98%;
  }
  .selectContainer {
    flex-direction: column;
    gap: 2%;
  }

 
}