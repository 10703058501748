.secondColbg {
  background-color: rgb(255, 249, 241);
  border-radius: 3rem;
  font-family: Roboto;
}
.formColbg{
  padding-top: 10%;
    background-color: white;
    height: 100vh;
    font-family: Roboto;
}
.login-logo {
    height: 10dvh;
}

.login-form-paragragh{
    margin-bottom: 0rem;
    font-family: Roboto;

}
.loginWidth{
    width: 400px;

}

.inputField {
    width: 400px;
    border: none;
    border-bottom: 1px solid gray;
    transition: border-bottom 0.3s ease;
    outline: none;
    font-family: Roboto;
  }
  
  .inputField:focus {
    border-bottom: 1px solid black;
    transition: border-bottom 0.3s ease;
  }
  .label{
    color:gray;
    font-size: 10px;
    font-family: Roboto;
  }

  .linkForgotPassword {
    font-size: 13px;
    text-decoration: none;
    color: black;
    font-family: Roboto;
  }
  .linkForgotPassword:hover{
    color:black
  }
  .linkForgotPassword:active{
    color: black;
  }

  .forgetPassword{
  
    max-width: 419px;
    width: 398px;
    text-align: right;
    font-family: Roboto;
  }

  .login-button {
    width: 400px;
    background: linear-gradient(180deg, #ff9914, #ff3b01);
    color: white; 
    border: none;
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;
    margin-top: 10px;
  
  }
  
  .login-button:hover {

   background: linear-gradient(180deg, #ff9914, #ff3b01);
    
  }

  .fontFamily{
    font-family: Roboto;
  }

  .carousel-indicators [data-bs-target] {
    background-color: rgb(255, 153, 20) !important;
  }

  .carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    padding: 0;
    margin-right: 15%;
    margin-bottom: -2rem !important;
    margin-left: 15%;
  }

  .carouselDiv{
    height: 90dvh;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    background-color: rgb(255, 249, 241);
    border-radius: 3rem;
    width:90%;
}
.caroselImage {
  margin-top: 7.5rem;
  width: 25%;
  margin-left: auto;
  margin-right: auto;
  display:flex;
  justify-content: center; 
}
.login-form {
  margin-left: 1rem;
}


.p1 {
  margin-top: 0;
  margin-bottom: 1rem;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  width: 75%;
}


.login-form input{
  font-size: 0.8rem !important;

}
.login-form input::placeholder{
  font-size: 0.8rem !important;
  padding-left: 0px !important;

}
.container-fluid,.row{
  overflow:hidden;
}

@media (max-width: 795px) {
  .login-button {
  width:250px !important ;
  margin: 0 0 0 41px !important;


  }
  .inputField{
    width:250px !important;
  margin: 0 0 0 41px !important;

  }
  .label{
    width:250px !important;
    margin: 0 0 0 41px !important;
  }
  .forgetPassword{
    width: 295px !important;
  }
  .loginWidth{
width: 370px !important;
  }
 
  .inputField > p {
    margin-left: 10px !important;
}
.email-validator{
  margin: 0 0 0 38px;
}

}
@media (max-width: 576px) {
  .log-1{
    height: 500px !important;
  }
  .resetpass{
    height: 67vh !important;

  }
}