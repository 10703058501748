
.main {
  width: 100%;
  font-family: Poppins;
  padding-bottom: 2%;
}

.contentContainer {
  width: 97.5%;
  box-shadow: 0px 1px 8px 0px #00000026;
  margin-left: 1%;
  padding: 1%;
  border-radius: 12px;
  font-family: Poppins;
}

.section {
  width: 100%;
  padding: 0% 1%;
}

.section p {
  margin: 0;

  font-size: 1.1rem;
  font-weight: 500;
}

.fieldContainer {
  width: 100%;
  display: flex;
  margin-top: 1%;
  /* padding: 0 1%; */
}

.feildLeft {
  width: 50%;
  padding: 0 1%;
}
.feildRight {
  width: 50%;
  padding: 0 1%;
}

.levelDetailsHeading {
  font-size: 1.1rem;
  font-weight: 500;
  padding-left: 2%;
  margin: 0;
}

.fieldLabel {
  margin-top: 2px;
}
.fieldLabel p {
  margin: 0;
  font-size: 0.8rem;
  font-weight: 500;
}
.fieldInput {
  width: 80%;
  margin-top: 2%;
}
.RichEditor-root{
  min-height: 250px!important;
}

.fieldInputTwo {
  width: 80%;
  margin-top: 2%;
  display: flex;
  gap: 5%;
}

.dateInput {
  width: 47.5%;
}
.dateInput input {
  width: 100% !important;
}

.fieldInput input {
  background-color: #fff9f1;
  outline: none;
  border: none;
  width: 100%;
  height: 3rem;
  border-radius: 8px;
  padding-left: 2%;
  font-size: 0.8rem;
}
.fieldInput input::placeholder {
  font-size: 0.7rem !important;
  padding-left: 20px;
}

.fieldInputTwo input {
  background-color: #fff9f1;
  outline: none;
  border: none;
  width: 47.5%;
  height: 3rem;
  border-radius: 8px;
  padding-left: 2%;
  font-size: 0.8rem;
}
.fieldInputTwo input::placeholder {
  font-size: 0.7rem !important;
  padding-left: 20px;
}

.rule {
  display: flex;
  width: 100%;
  gap: 2%;
}
.rule input {
  width: 93%;
}
.rule button {
  width: 5% !important;
  background-image: url(../../../../assets/images/deleteIcon.png) !important;
  background-size: 20px;
  background-repeat: no-repeat;
  background-position-x: 100% !important;
  background-position-y: 50%;
}

.fieldInputRule {
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
}

/* WebKit */
.fieldInputRule::-webkit-scrollbar {
  display: none;
}

/* Firefox */
.fieldInputRule {
  scrollbar-width: none;
}

/* Edge */
.fieldInputRule {
  -ms-overflow-style: none;
}

/* Firefox and Edge track */
.fieldInputRule {
  scrollbar-color: transparent transparent;
}

.fieldInputRule button {
  width: 30%;
  margin-top: 2%;
  border: none;
  background: transparent;
  font-size: 0.9rem;
  background-image: url(../../../../assets/images/addMore.png);
  background-size: 15px;
  background-repeat: no-repeat;
  background-position-x: 7%;
  background-position-y: 50%;
}

.fieldInput textarea {
  background-color: #fff9f1;
  outline: none;
  border: none;
  width: 100%;
  height: 5rem;
  border-radius: 8px;
  resize: none;
  padding: 1% 4%;
  font-size: 0.8rem;
}

.fieldDate {
  width: 80%;
  display: flex;
  gap: 2%;
}
.fieldDate input {
  background-color: #fff9f1;
  outline: none;
  border: none;
  width: 100%;
  height: 3rem;
  border-radius: 8px;
  padding-left: 2%;
  font-size: 0.8rem;
}

.footer {
  margin-top: 2%;
  display: flex;
  gap: 2%;
  padding-bottom: 2%;
}
.footer button {
  font-weight: 400;
  font-size: 0.7rem;
  color: #ffffff;
  background: #ff9914;
  border: none;
  width: 10%;
  padding: 0 2%;
  height: 5.5dvh;
  border-radius: 18px;
}

.footer button:first-child {
  color: #000000;
  background-color: #ffffff;
  border: 1px solid #95a1bb;
}

input[type="date"] {
  appearance: none !important;
}

input[type="date"]:focus {
  outline: none;
}

.horizontalRow {
  width: 98%;
  margin-left: 1%;
  background-color: #e3e3e3;
  height: 3px;
  margin-top: 3%;
  margin-bottom: 3%;
}

.fieldContainerTwo {
  background-color: #fcfcfc;
  padding: 0.5%;
  border-radius: 8px;
  padding: 2% 0;
  position: relative;
}

.stepperSubDiv {
  background: transparent;
  border-radius: 12px;
  /* padding: 0 1.4em; */
  color: #000000;
  position: relative;
}

.stepperBtn {
  cursor: pointer;
  outline: none;
  box-shadow: none;
  border: none;
  background: #f68b21;
  color: white;
  border-radius: 12px;
  padding: 0.5em 1.4em;
  font-size: 1.2em;
  font-weight: 500;
  margin: 1.4em;
}

.levelDetailsMain {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.levelDetailsMain button {
  width: 17%;
  border: none;
  background: transparent;
  font-size: 0.9rem;
  background-image: url(../../../../assets/images/addMore.png);
  background-size: 15px;
  background-repeat: no-repeat;
  background-position-x: 13%;
  background-position-y: 50%;
}

.taskDetailsMain {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2%;
}

.taskDetailsMain p {
  margin: 0;
}

.taskAdd {
  width: 15%;
  background: transparent;
  font-size: 0.9rem;
  background-image: url(../../../../assets/images/addMore.png);
  background-size: 15px;
  background-repeat: no-repeat;
  background-position-x: 7%;
  background-position-y: 50%;
  border: none;
  outline: none;
}

.deleteItem {
  position: absolute;
  left: 93%;
  width: 3% !important;
  background: transparent;
  background-image: url(../../../../assets/images/deleteIcon.png) !important;
  background-size: 20px;
  background-repeat: no-repeat;
  background-position-x: 100% !important;
  background-position-y: 50%;
  border: none;
  outline: none;
  height: 3dvh;
}

.deleteItemTwo {
  position: absolute;
  left: 95%;
  width: 3% !important;
  background: transparent;
  background-image: url(../../../../assets/images/deleteIcon.png) !important;
  background-size: 20px;
  background-repeat: no-repeat;
  background-position-x: 100% !important;
  background-position-y: 50%;
  border: none;
  outline: none;
  height: 3dvh;
}

.errorMessage {
  margin: 0;
  color: red;
  font-size: 0.7rem;
  margin-top: 0.5rem;
}

@media screen and (max-width: 767px) {
  .contentContainer {
    width: 97.5%;
    box-shadow: 0px 1px 8px 0px #00000026;
    margin-left: 1%;
    padding: 3% 1%;
    border-radius: 12px;
    font-family: Poppins;
  }

  .fieldContainer {
    width: 100%;
    flex-direction: column;
  }

  .feildLeft {
    width: 100%;
    padding: 0 1%;
  }
  .feildRight {
    width: 100%;
    padding: 0 1%;
  }

  .fieldLabel {
    margin-top: 3%;
  }
  .fieldLabel p {
    margin: 0;
    font-size: 0.8rem;
    font-weight: 500;
  }
  .fieldInput {
    width: 100%;
    margin-top: 2%;
  }

  .fieldInputTwo {
    width: 100%;
    margin-top: 2%;
    display: flex;
    gap: 5%;
  }

  .rule {
    display: flex;
    width: 100%;
    gap: 2%;
  }
  .rule input {
    width: 93%;
  }
  .fieldInputRule button {
    width: 50%;
    margin-top: 2%;
  }

  .levelDetailsMain button {
    width: 35%;
    background-position-x: 0%;
    background-position-y: 50%;
  }

  .taskAdd {
    width: 45%;
  }

  .deleteItem {
    left: 90%;
    width: 8% !important;
  }

  .deleteItemTwo {
    left: 90%;
    width: 8% !important;
  }

  .fieldInput textarea {
    width: 100%;
    height: 12dvh;
    padding: 2% 4%;
  }

  .fieldDate {
    width: 100%;
    display: flex;
    gap: 2%;
  }

  .footer {
    margin-top: 2%;
    display: flex;
    gap: 2%;
    padding-bottom: 2%;
  }
}
.pointsbx .card-header{
  border: 0px;
  border-bottom: 1px solid #000;
}

.pointsbx .card-body{
  padding: 0px 0px!important;
}
.pointsbx .card-body .CreateCampaign_stepperSubDiv__vtcUA{
  padding: 0px!important;
}
.card-header{
  padding: 0px 10px!important;
}
.stepperDiv{
  margin-top: 10px;
}
.pointsbx{
  width: 93%!important;
  margin: 0px 20px!important;
}