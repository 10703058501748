.main{
    margin-top: 4%;
}
.singleDetailContainer{
    width: 100%;
    display: flex;
    font-family: Roboto;
}
.detailContainer{
    width: 50%;
}
.detailsHeading{
    width: 100%;
}
.details{
    width: 100%;
    display: flex;
}
.profileImage{
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.profileImageCircle{
    width: 90%;
    height: 100%;
}
.profileImageCircle img {
    width: 90%;
    height: 100%;
    object-fit: contain;

}
.detailsContent{
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding-left: 4%;
}
.singleDetail{
    width: 100%;
    display: flex;
}
.singleDetailLeft{
    width: 35%;
}
.singleDetailRight{
    width: 65%;
}

.singleDetailLeftTwo{
    width: 70%; 
}

.singleDetailRightTwo{
    width: 30%;
    text-overflow: ellipsis;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.singleDetailRightTwo > span {
    display: inline-block;
    width: 14%;
}

.singleDetailRightTwo > span > img {
    width: 0.8rem;
}

.detailsHeading li{
    font-size: 0.8rem;
    font-weight: 600;
}

.singleDetail p{
    font-size: 0.8rem;
    margin: 0;
    padding-top: 2%;
}

.singleDetailLeft p{
    font-weight: 500;
}

.viewPassport{
    border: none;
    outline: none;
    padding: 0.4rem 1.4rem;
    font-size: 0.75rem;
    background: #FFF9F1;
    color: #FF9914;
    border-radius: 0.4rem;
}
@media (max-width:767px){
    .singleDetailContainer{
        display: block;
    }
    .detailContainer{
        width: 100%;
    }
    .detailsHeading{
        margin-top: 10px;
    }
    .details{
        padding-left: 0px!important;
    }
}