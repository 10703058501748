.modal {
    top: 5%;
    left: 25%;
    right: 25%;
    position: fixed;
    border-radius: 0 !important;
    width:50dvw !important;
    height: 95dvh;
    overflow-y: auto;
  }

  .ant-modal-body{
    overflow-y: hidden;
  }
   
  
  .contentMain{
    box-sizing: border-box;
    height: 80dvh;
    padding: 3%;
    overflow-y: hidden;
  }
  
  .imageSection{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 75dvh;
  }

  .contentMain img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }
  
  
  
  
  @media screen and (max-width: 767px) {
    .modal{
      width:100vw !important;
      margin:0px 8px 0px 0px !important;
    }
    }