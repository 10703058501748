.main {
    width: 100%;
    font-family: Poppins;
    padding-bottom: 2%;
  }
  
  .pageHeader {
    display: flex;
    justify-content: space-between;
    padding: 2% 2% 3% 0 !important;
    background-color: #fcfcfc;
  }
  
  .pageHeaderLeft {
    width: 60%;
  }
  
  .pageHeaderRight {
    width: 40%;
    display: flex;
    justify-content: flex-end;
    gap: 2%;
  }
  .pageHeaderLeft p {
    font-weight: 600;
    font-size: 1.1rem;
    padding-left: 1.5%;
    width: 100%;
  }
  
  .activePageStyle {
    color: #ff9914;
    margin-left: 1.5%;
  }
  .pageHeaderRight button {
    font-weight: 400;
    font-size: 0.7rem;
    color: #ffffff;
    background: #ff9914;
    border: none;
    width: 22%;
    padding: 0 2%;
    height: 5.5dvh;
    border-radius: 18px;
  }
  
  .pageHeaderRight button:first-child {
    color: #000000;
    background-color: #ffffff;
    border: 1px solid #95a1bb;
  }


.subMain{
    width: 97.5%;
    box-shadow: 0px 1px 8px 0px #00000026;
    margin-left: 1%;
    padding: 1%;
    border-radius: 12px;
    font-family: Poppins;
}

.subMainHeading{
    width: 100%;
}

.subMainHeading p{
    font-size: 0.9rem;
    font-weight: 600;
    margin: 0;
    padding: 1% 2%;
}

.detailsContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
}

.singleDetailContainer{
    width: 100%;
    display: flex;
    /* gap: 10%; */
}

.singleDetail {
    width: 50%;
    display: flex;
    gap: 2%;
    justify-content: space-between;
    position: relative; /* Required for positioning the pseudo-element */
    margin-bottom: 0.5%;
  }
  
  .singleDetail p{
    font-size: 0.8rem;
    margin: 0;
  }

.singleDetail p:first-child{
    font-size: 0.9rem;
    font-weight: 600;
    padding-left: 5%;
    width: 40%;
}

.singleDetail p:last-child{
    width: 60%;
    color: #595959;
    ;
}

/* 
.kycViewDetails{
    color: #ff9914;
    text-decoration-line: underline;
    cursor: pointer;
  } */