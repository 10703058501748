.subMain {
  width: 97%;
}

.detailsContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.singleDetailContainer {
  width: 100%;
  display: flex;
  /* gap: 10%; */
}

.singleDetail {
  width: 50%;
  display: flex;
  gap: 2%;
  justify-content: space-between;
  position: relative; /* Required for positioning the pseudo-element */
  margin-bottom: 0.5%;
}

.singleDetail p {
  font-size: 0.8rem;
  margin: 0;
}

.singleDetail p:first-child {
  font-size: 0.9rem;
  font-weight: 600;
  padding-left: 5%;
  width: 40%;
}

.singleDetail p:last-child {
  width: 60%;
  color: #595959;
}

.achievementInfo {
  width: 100%;
  background-color: #fcfcfc;
  display: flex;
  flex-direction: column;
  margin-top: 2%;
  padding: 2%;
  border-radius: 8px;
}

.achievementContainer {
  width: 90%;
  display: flex;
}
.singleAchievement {
  width: 50%;
  display: flex;
  margin-bottom: 1%;
}

.singleAchievement p {
  width: 45%;
  font-size: 0.8rem;
  margin: 0;
}

.singleAchievement p:first-child {
  font-size: 0.95rem;
  font-weight: 600;
}
.note {
  width: 100%;
  display: flex;
  align-items: center;
}

.note p {
  font-size: 0.8rem;
  margin: 0;
}

.note p:first-child {
  width: 8%;
  font-size: 0.95rem;
  font-weight: 600;
  text-transform: uppercase;
}
.paidBy {
  width: 100%;
  display: flex;
  align-items: center;
}

.paidBy p {
  font-size: 0.8rem;
  margin: 0;
}

.paidBy p:first-child {
  width: 8%;
  font-size: 0.95rem;
  font-weight: 600;
  text-transform: uppercase;
}

.paymentButton {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 2%;
}
.disabledPayButton {
  background-color: #a7a7a7;
  font-size: 0.7rem !important;
  color: #ffffff;
  font-weight: 500;
  padding: 1.5% 0;
  width: 15%;
  border-radius: 16px !important;
  border: none !important;
  outline: none !important;
}
.payButton {
  font-size: 0.7rem !important;
  color: #ffffff;
  font-weight: 500;
  padding: 1.5% 0;
  width: 15%;
  border-radius: 16px !important;
  background-color: #ff9914 !important;
  border: none !important;
  outline: none !important;
}

@media screen and (max-width: 767px) {
  .detailsContainer {
    gap: 2dvh;
  }

  .singleDetailContainer:first-child{
    margin-top: 4%;
  }
  .singleDetailContainer {
    flex-direction: column;
    gap: 2dvh;
  }
  .singleDetail {
    width: 100%;
    gap: 2%;
  }

  .singleDetail p {
    font-size: 0.8rem;
    margin: 0;
  }
  
  .singleDetail p:first-child {
    font-size: 0.75rem;
    font-weight: 600;
    padding-left: 5%;
    width: 45%;
  }
  
  .singleDetail p:last-child {
    width: 55%;
    font-size: 0.70rem;
  }

  .achievementInfo {
    flex-direction: column;
  }
  
  .achievementContainer {
    width: 100%;
    flex-direction: column;
  }
  .singleAchievement {
    width: 100%;
    display: flex;
    margin-bottom: 2dvh;
  }
  
  .singleAchievement p {
    font-size: 0.75rem;
  }
  
  .singleAchievement p:first-child {
    font-size: 0.75rem;
    font-weight: 600;
  }

  .note {
    flex-direction: column;
    align-items: center;
    margin-top: 4%;
  }
  
  .note p {
    font-size: 0.8rem;
    margin: 0;
  }
  
  .note p:first-child {
    width: 100%;
    text-align: center;
    font-size: 0.95rem;
  }
  .paidBy {
    width: 100%;
  }
  
  .paidBy p {
    font-size: 0.75rem;
    margin: 0;
    font-weight: 600;
  }
  
  .paidBy p:first-child {
    width: 45%;
    font-size: 0.95rem;
    font-weight: 600;
    text-transform: uppercase;
  }
  
  .payButton {
    padding: 2% 0;
    width: 25%;
  }
  
}
