.tableMain {
  width: 100%;
  margin-top: 2%;
  border-radius: 8px;
}

.poojaTableContainer{
  margin-top: -2.5%;
}

@media screen and (max-width: 767px) {
  .poojaTableContainer{
    margin-top: 0%;
  }
}
