.headerMain{
    width: 100%;
    height: 9dvh;
    display: flex;
}

.headerLeft{
    width: 30%;
}
.headerRight{
    width: 70%;
    display: flex;
    gap: 3%;
    align-items: center;
    justify-content: flex-end;
}

.headerRight input {
    width: 45%;
    height: 4dvh;
    background-color: #E3E3E3;
    outline: none;
    border: none;
    border-radius: 12px;
    margin-left: 40%;
    background-image: url(../../assets/images/searchIcon.png);
    background-repeat: no-repeat;
    background-size: 3.5%;
    background-position-x: 95%;
    background-position-y: 45%;
    padding-left: 2%;
    font-size: 0.8rem;
}

.notificationIcon{
   width: 2.5%;
}

.userIcon{
width: 4.5%;
}

@media screen and (max-width: 767px) {
    .userIcon{
     display: none;
    }
  }
