.modal {
    top: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    height: 100dvh;
    min-height: 80dvh;
    max-height: 100dvh;
    width: 29dvw !important;
  }
  
  .ant-modal-content {
    min-height: 80dvh;
    max-height: 100dvh;
    height: calc(100% - 55px);
  }

  .ant-modal-body{
    min-height: 80dvh;
    max-height: 100dvh;
    height: 80dvh;
  }
  
  .ant-modal-body {
    overflow-y: auto;
  }
  