/* .main {
  width: 97%;
  border-radius: 8px;
  margin-top: -1%;
  padding-bottom: 2%;
}

.fieldContainer {
  width: 100%;
  display: flex;
}

.feildLeft {
  width: 50%;
}
.feildRight {
  width: 50%;
}

.fieldLabel {
  margin-top: 3%;
}
.fieldLabel p {
  margin: 0;
  font-size: 0.8rem;
  font-weight: 500;
}
.fieldInput {
  width: 80%;
  margin-top: 2%;
}

.fieldInput input {
  background-color: #fff9f1;
  outline: none;
  border: none;
  width: 100%;
  height: 3rem;
  border-radius: 8px;
  padding-left: 2%;
  font-size: 0.8rem;
}
.fieldInput input::placeholder {
  font-size: 0.7rem !important;
  padding-left: 20px;
}

.fieldDate {
  width: 80%;
  display: flex;
  gap: 2%;
}
.fieldDate input {
  background-color: #fff9f1;
  outline: none;
  border: none;
  width: 100%;
  height: 3rem;
  border-radius: 8px;
  padding-left: 2%;
  font-size: 0.8rem;
}

.footer {
  margin-top: 2%;
  display: flex;
  gap: 2%;
  padding-bottom: 2%;
}
.footer button {
  font-weight: 400;
  font-size: 0.7rem;
  color: #ffffff;
  background: #ff9914;
  border: none;
  width: 10%;
  padding: 0 2%;
  height: 5.5dvh;
  border-radius: 18px;
}

.footer button:first-child {
  color: #000000;
  background-color: #ffffff;
  border: 1px solid #95a1bb;
}

input[type="date"] {
  appearance: none !important;
}

input[type="date"]:focus {
  outline: none;
}


.regionDropdown{
  width: 100%;
}

select {
  width: 100%;
  min-height: 10px;
  height: 3rem;
  border-radius: 8px;
  border: none;
  box-shadow: none;
  font-size: 0.7rem;
  background: #FFF9F1;
  font-weight: 400;
  padding-left: 14px;
}

@media screen and (max-width: 767px) {

.footer button:first-child {
  color: #000000;
  background-color: #ffffff;
  border: 1px solid #95a1bb;
  width:25%;
}
.footer button{
  width:25%;
}
}


 */





 .tableMain {
  width: 100%;
  margin-top: 2%;
  border-radius: 8px;
}

.filterExport {
  width: 100%;
  display: flex;
  gap: 2%;
  /* align-items: center; */
  justify-content: flex-end;
  padding: 0 0 1% 0;
}

.filterExport p{
  width: 50%;
  margin: 0;
}

.search {
  width: 13%;
}

.search input {
  width: 100%;
  background-color: #fff9f1;
  border-radius: 8px;
  background-image: url(../../../assets/images/searchIcon.png);
  background-repeat: no-repeat;
  background-size: 10%;
  background-position-x: 90%;
  background-position-y: 45%;
  padding: 3%;
  font-size: 0.8rem;
  outline: none;
  border: none;
}

.search input:focus {
  background-image: none;
}

.export {
  border: 1px solid #e6e6e6;
  border-radius: 15px;
  width: 20%;
}

.export p {
  width: 100%;
  margin: 0;
  padding: 2.5%;
  font-size: 0.7rem;
  font-weight: 600;
  text-align: center;
}

.export > p > a{
  text-decoration: none;
  color: #000000;
}

.filterDiv {
  border: 1px solid #e6e6e6;
  border-radius: 15px;
  width: 8%;
}

.filterDiv p {
  width: 100%;
  margin: 0;
  padding: 5% 0;
  font-size: 0.7rem;
  font-weight: 600;
  text-align: center;
  background-image: url(../../../assets/images/filter.png);
  background-size: 14px;
  background-position-x: 18%;
  background-position-y: 50%;
  background-repeat: no-repeat;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 0% !important;
  table-layout: fixed;
  margin-left: 0 !important;
}

th,
td {
  text-align: center !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 0.7rem;
}

thead tr {
  box-shadow: 0px 0px 4px 0px #ff99144d !important;
  border-radius: 8px;
}

tbody tr {
  box-shadow: 0px 0px 2px 0px #999fa980;
  border-radius: 8px;
}

tr {
  margin-bottom: 10px !important;
}

td {
  width: 25%;
  max-width: 25%;
  padding: 1.5%;
}

th {
  padding: 1%;
  font-size: 0.7rem;
  font-weight: 500;
  letter-spacing: 0.4px;
  color: #ff9914;
  position: relative;
}

th:not(:last-child)::after {
  content: "";
  position: absolute;
  top: 25%;
  right: 0px;
  bottom: 0;
  width: 2px;
  height: 3dvh;
  background-color: #E6E6E6 !important;
}

.footer {
  width: 98%;
  margin-left: 1%;
  display: flex;
  justify-content: space-between;
  margin-top: 2%;
}
.action {
  display: flex;
  width: 50%;
  align-items: center;
  padding-bottom: 2%;
  gap: 2%;
}

.actionButton {
  width: 20%;
  text-align: center;
  padding: 1.5%;
  border-radius: 22px;
  border: 1px solid #999fa9;
  font-size: 0.8rem;
  transition: all 0.3s ease; 
  cursor: pointer;
}

.activeButton {
  background-color: #ff9914;
  color: #ffffff;
  font-size: 0.8rem;
  border: none !important;
}

.page {
  width: 50%;
  text-align: right;
}

.page span {
  font-size: 0.8rem;
}

.page span:first-child {
  font-size: 0.8rem;
  padding: 0.3% 1.6%;
  border: 1px solid #00054f26;
  border-radius: 5px;
}

.actionTd{
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: center;
}


.actionTd img{
  width:15px;
  height:15px;
  margin-right:15px;
  cursor: pointer;
}

.viewDetails{
  color: #ff9914;
  text-decoration-line: underline;
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .filterDiv {
    margin-left: 33%;
    padding: 0%;
    width: 32%;
    margin-top: 1%;
  }
  .export{
    margin-left: 24%;
    padding: 2%;
    width: 50%;
  }
  .filterExport {
    width: 100%;
    display: block !important;
    gap: 2%;
    align-items: center;
    justify-content: center;
    padding: 0 0 1% 0;
  }
  .filterExport {
    width: 100%;
  }

  .search {
    width: 100%;
    margin-top: 2%;
    display: flex;
    justify-content: center;    
    padding: 0 1% 1% 0;
  }
  
  .search input {
    width: 50%;
  }

 
  
 
  .campaignTableMain{
    width: 100%;
    overflow-x: auto !important;
  }
  table {
    width: 300dvw !important;
    border-collapse: collapse;
    margin-top: 5%;
    table-layout:auto !important;
    margin-left: 1%;

  }

  td {
    width: 20dvw !important;
    max-width: 30dvw;
    height: 8dvh;
    padding: 0% !important;
  }

  td:nth-child(1){
    width: 20dvw !important; 
  }
  td:nth-child(2){
    width: 20dvw !important; 
  }
  td:nth-child(3){
    width: 20dvw !important; 
  }
  td:nth-child(4){
    width: 20dvw !important; 
  }
}
