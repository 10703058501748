.main {
    width: 100%;
    padding: 0 1%;
  }
  

  
  .contentContainerTask {
    border-radius: 12px;
    box-shadow: 0 1px 8px 0 #00000026;
    font-family: Poppins;
    margin-left: 1%;
    padding: 1%;
    width: 97.5%;
  }
  
  .inputContainer {
    display: flex;
    width:100%;
    margin: 20px 0;
    gap: 10rem;
  }
  
  .inputBox {
    border-radius: 8px;
    border: none;
    outline: none;
    background-color: #fff9f1;
    padding: 15px;
    width: 100%;
  }
  .fieldContainer {
    width: 100%;
    display: flex;
  }
  
  .feildLeft {
    width: 50%;
  }
  .feildRight {
    width: 50%;
  }
  
  .fieldLabel {
    margin-top: 3%;
  }
  .fieldLabel p {
    margin: 0;
    font-size: 0.8rem;
    font-weight: 500;
  }
  .fieldInput {
    width: 80%;
    margin-top: 2%;
  }
  
  .fieldInput input {
    background-color: #fff9f1;
    outline: none;
    border: none;
    width: 100%;
    height: 3rem;
    border-radius: 8px;
  }
 
  /* Responsive styles */
  @media screen and (max-width: 767px) {

  
    .inputBox {
      width: 100%;
    }
  
    .contentContainerTask {
      width: 100%;
      margin-left: 0;
    }
    .fieldContainer {
      display: block;
    }
    .feildLeft {
      width: 100%;
    }
    .feildRight {
      width: 100%;
    }
    .fieldInput {
      width: 100%;
      margin-top: 2%;
      display: flex;
      justify-content: center;
    }
  }
  