
  .icon {
    font-family: "Material Icons", serif;
    font-style: normal;
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
  
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: "liga";
  }
  
  /* VARIABLES */
  
  :root {
    --main-color: #1a8fff;
    --text-color: #777;
    --text-color-light: #ccc;
    --border-color: #eee;
    --bg-color: #f9f9f9;
    --neutral-color: #fff;
  }
  
  /* GENERAL */
  
  * {
    box-sizing: border-box;
  }
  
  body {
    font-family: "Open Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
    font-size: 1em;
    font-weight: 300;
    line-height: 1.5;
    color: var(--text-color);
    background: var(--bg-color);
    position: relative;
  }
  
  
  main {
    display: block;
    margin: 0 auto;
    margin-top: 5em;
    max-width: 50em;
  }
  
  /* GRID */
  
  .row {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }
  
  .row-middle {
    align-items: center;
  }
  
  .col {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
    text-align: center;
  }
  
  .col-start {
    justify-content: flex-start;
    text-align: left;
  }
  
  .col-center {
    justify-content: center;
    text-align: center;
  }
  
  .col-end {
    justify-content: flex-end;
    text-align: right;
  }
  
  /* Calendar */
  
  .calendar {
    display: block;
    position: relative;
    width: 100%;
    background: var(--neutral-color);
  }
  
  
  .calendar .days {
    text-transform: uppercase;
    font-weight: 600;
    color: #000;
    font-size: 70%;
    padding: 5% 3%;
  }
  
  .calendar .body .cell {
    text-align: center;
    position: relative;
    padding: 2.8% 0;
    overflow: hidden;
    cursor: pointer;
    /* background: var(--neutral-color);
    transition: 0.25s ease-out; */
    font-size: 0.8em;
  }
  
  .calendar .body .cell:hover {
    /* background: var(--bg-color);
    transition: 0.5s ease-out; */
    border-radius: 50%;
  }
  .calendar .body span {
    display: inline-block;
    width: 55%;
    height: 100%;
    padding: 8% 0;
  }
  
  .calendar .body .selected {
    border-radius: 50%;
    background-color: orange;
  }
  /* .calendar .body .today {
    border-radius: 50%;
    background-color: red;
  } */
  .calendar .body .row {
    /* border-bottom: 1px solid var(--border-color); */
  }
  
  /* .calendar .body .row:last-child {
    border-bottom: none;
  } */
  
  .calendar .body .cell:last-child {
    border-right: none;
  }
  
  .calendar .body .cell .number {
    font-weight: 500;
  }
  
  .calendar .body .disabled {
    color: var(--text-color-light);
    pointer-events: none;
  }
  
 
  .calendar .body .col {
    flex-grow: 0;
    flex-basis: calc(100% / 7);
    width: calc(100% / 7);
  }
  