.navbarContainer {
  width: 100%;
  display: flex;
  height: auto;
  gap: 0.2%;
  font-family: Poppins;
}

.navbarMain {
  width: 17%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
}

.page {
  box-sizing: border-box;
  width: 82.8%;
  background-color: #ffffff;
}

.navbarHeader {
  width: 100%;
  display: flex;
  align-items: center;
}

.navbarHeader img {
  width: 24%;
  margin-left: 10%;
  margin-top: 5%;
}

.navbarHeader span {
  font-size: 1.3rem;
  font-weight: 600;
  margin-left: 5%;
  margin-top: 5%;
}

.menuItems {
  width: 100%;
  margin-top: 15%;
  display: flex;
  flex-direction: column;
  gap: 1.1rem;
  text-align: left;
  padding-bottom: 15%;
}

.menu {
  width: 97%;
  cursor: pointer;
}

.menu img {
  width: 18%;
  height: 18%;
  padding-top: 3.5%;
  padding-bottom: 3.5%;
  padding-left: 10%;
}

.menu a {
  width: 100%;
  text-decoration: none;
  font-size: 0.7rem;
  color: black;
  display: flex;
}

.menu > a > p {
  font-size: 0.75rem;
  margin: 0;
  padding: 3.5% 0;
  padding-left: 2%;
}

.active {
  background: linear-gradient(92.32deg, #ff9914 0.43%, #ff3b01 100%);
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}

.active a {
  color: white;
}

.logoutMenu {
  display: flex;
  
}

.logoutMenu img {
  width: 18%;
  height: 18%;
  padding-top: 3.5%;
  padding-bottom: 3.5%;
  padding-left: 10%;
}

.logoutMenu p {
  font-size: 0.8rem;
  margin: 0;
  padding: 3.5% 0;
  padding-left: 2%;
}

.subMenuMain {
  width: 95%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  text-align: left;
  color: #ffffff;
  padding-top: 2%;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  padding: 5% 0;
}

.subMenuMainOpen {
  background: linear-gradient(92.53deg, #ff9914 -2.87%, #ff4c04 100%);
}

.subMenu {
  width: 95%;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}

.subMenu img:first-child {
  height: 90%;
  padding-left: 10%;
}

.subMenu img:last-child {
  height: 25%;
  margin-left: 4%;
  /* margin-top: 4%; */
}

.subMenu a {
  width: 100%;
  text-decoration: none;
  font-size: 0.8rem;
  display: flex;
}

.subMenu p {
  width: max-content;
  text-decoration: none;
  font-size: 0.8rem;
  color: black;
  display: flex;
  padding-left: 2% !important;
  margin: 0;
}

.subMenuItems {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding-left: 15% !important;
  font-size: 0.7rem;
}

.subMenuItemsActive p {
  color: #ffffff !important;
  font-weight: bold;
}

.activeLabel {
  color: #ffffff !important;
}

.subMenuSingle a {
  width: 100%;
  text-decoration: none;
  font-size: 0.6rem;
  display: flex;
}

.subMenuSingle p {
  width: 100%;
  text-decoration: none;
  font-size: 0.65rem;
  color: #fff;
  display: flex;
  padding-left: 2% !important;
  margin: 0;
}

.mobileMenuIcon {
  display: none;
  cursor: pointer;
}

/* Hamburger Bars */
.bar {
  width: 25px;
  height: 3px;
  background-color: #333;
  margin: 5px 0;
  transition: 0.4s;
}

/* Hamburger Animation for Bars */
.bar:nth-child(2) {
  transition-delay: 0.2s;
}

.bar:nth-child(3) {
  transition-delay: 0.4s;
}

.showMobileMenu {
  display: block;
}

@media screen and (max-width: 767px) {
  .navbarContainer {
    flex-direction: column;
  }
  .navbarMain {
    width: 100%;
    height: auto;
    overflow-y:auto;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 1;
  }
  .showMobileMenu{
    background-color:#444343;
    z-index: 1;
  }
  .hideMobileMenu{
    display: none;
  }
  .hidePage{
    display: none;
  }
  .navbarHeader {
    width: 100%;
    display: flex;
    align-items: center;
    padding:2%;
  }
  
  .navbarHeader img {
    width: 14%;
    margin-left: 2%;
    margin-top: 2%;
  }
  
  .navbarHeader span {
    margin-left: 3%;
    margin-top: 2%;
  }
  .mobileMenuIcon {
    display: block;
    cursor: pointer;
    position: relative;
    margin-left: 60%;
  }

  .page {
    width: 100%;
  }
}

