.main {
  width: 100%;
  font-family: Poppins;
  padding: 0 1%;
}

.pageHeader {
  display: flex;
  justify-content: space-between;
  padding: 2% 2% 3% 0 !important;
  background-color: #fcfcfc;
}
.pageHeaderLeft {
  width: 60%;
}

.pageHeaderLeft p {
  font-weight: 600;
  font-size: 1.1rem;
  padding-left: 1.5%;
  width: 100%;
}
.pageHeaderRight {
  width: 40%;
  display: flex;
  justify-content: flex-end;
}

.buttonItem {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 5%;
}

.pageHeaderRight button {
  font-weight: 400;
  font-size: 0.7rem;
  color: #ffffff;
  background: linear-gradient(95.2deg, #ff9914 0%, #ff4c04 54.17%);
  border: none;
  padding: 0 3.5%;
  height: 5.5dvh;
  border-radius: 8px;
}

.contentContainer {
  width: 98%;
  box-shadow: 0px 1px 8px 0px #00000026;
  margin-left: 1%;
  padding: 1%;
  border-radius: 12px;
  font-family: Poppins;
}
.menuContainer {
  width: 100%;
  display: flex;
  gap: 1%;
  padding: 1% 2%;
  font-family: Poppins;
}
.singleMenu {
  width: 12%;
  text-align: center;
  padding: 0.5% 0 0 0;
}
.singleMenu p {
  width: 100%;
  margin: 0;
  font-size: 0.9rem;
  color: #000000;
  cursor: pointer;
  text-decoration: none;
  position: relative;
}

.singleMenu p::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 100%;
  height: 1px;
  background-color: #999fa9;
}

.activeMenu {
  background-color: #ff991440;
}

.activeMenu p {
  color: #ff9914;
  font-family: Poppins;
  font-size: 0.8rem;
  font-weight: 600;
}

.activeMenu p::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -3px;
  width: 100%;
  height: 1px;
  background-color: #ff9914;
}

.activePage {
  margin-top: 2%;
}

@media screen and (max-width: 767px) {
  .Details_profilePic__ObmYA {
    width: 100%;
}
  .pageHeader {
    flex-direction: column;
    padding: 6% 2% !important;
  }
  .pageHeaderLeft {
    width: 100%;
  }

  .pageHeaderRight {
    width: 100%;
    justify-content: flex-start !important;
    padding-left: 2%;
  }

  .pageHeaderRight button {
    width: 43%;
    padding: 0 3.5%;
    height: 6.5dvh;
  }

  .buttonItem {
    justify-content: flex-start;
  }

  .contentContainer {
    padding: 3% 1%;
  }

  .singleMenu {
    width: 48%;
    text-align: center;
    padding: 2% 0 0 0;
  }

  .activeMenu {
    background-color: #ff991440;
  }

  .activeMenu p {
    color: #ff9914;
    font-family: Poppins;
    font-size: 0.8rem;
    font-weight: 600;
  }
  .activePage {
    margin-top: 2%;
  }

  .singleMenu {
    width: 48%;
    text-align: center;
    padding: 2% 0 0 0;
  }

  .activeMenu {
    background-color: #ff991440;
  }

  .activeMenu p {
    color: #ff9914;
    font-family: Poppins;
    font-size: 0.8rem;
    font-weight: 600;
  }
  .activePage {
    margin-top: 2%;
  }

  .menuContainer {
    width: 100%;
    display: flex;
    gap: 5%;
    padding: 1% 2%;
    font-family: Poppins;
  }
  .singleMenu {
    width: 30%;
    padding: 2% 0 0 0;
    margin-top: 2%;
  }
}
