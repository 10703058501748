.main {
    width: 100%;
    padding: 0 1%;
  }
  
  .pageHeader {
    display: flex;
    justify-content: space-between;
    padding: 2% 2% 3% 0 !important;
    background-color: #fcfcfc;
    font-family: Poppins;
  }
  
  .pageHeader p {
    font-weight: 600;
    font-size: 1.1rem;
    padding-left: 1.3%;
  }
  
  .pageHeader button {
    font-weight: 400;
    font-size: 0.7rem;
    color: #ffffff;
    background: linear-gradient(95.2deg, #ff9914 0%, #ff4c04 54.17%);
    border: none;
    padding: 0 1.5%;
    height: 5.5dvh;
    border-radius: 8px;
  }

  .taskCenterButton{
    width: 9dvw;
  }
  .contentContainerTask {
    width: 98.5%;
    display: flex;
    justify-content: center;
    padding: 0 1%;
    /* margin-top: -2.5%; */
    z-index: 1;
    background-color: #fff;
  }
  
  @media screen and (max-width: 767px) { 
    .pageHeader {
      flex-direction: column;
      padding: 6% 2% !important;
    }  

    .taskCenterButton {
      width: 30%;
      padding: 0 3.5%;
      height: 6.5dvh !important;
    }
  
  }