/* .tableMain {
  width: 100%;
  margin-top: 2%;
  border-radius: 8px;
}

.checkbox {
  display: flex;
  justify-content: space-evenly;
}
.SNoCheckBox {
  display: flex;
  justify-content: space-evenly;
  margin-left: 9%;
}

.filterExport {
  width: 100%;
  display: flex;
  gap: 2%;
  justify-content: flex-end;
  padding: 0 0 1% 0;
}

.filterExport p {
  width: 50%;
  margin: 0;
}

.search {
  width: 13%;
}

.search input {
  width: 100%;
  background-color: #fff9f1;
  border-radius: 8px;
  background-image: url(../../../assets/images/searchIcon.png);
  background-repeat: no-repeat;
  background-size: 10%;
  background-position-x: 90%;
  background-position-y: 45%;
  padding: 3%;
  font-size: 0.8rem;
  outline: none;
  border: none;
}

.search input:focus {
  background-image: none;
}

.export {
  border: 1px solid #e6e6e6;
  border-radius: 15px;
  width: 20%;
}

.export p {
  width: 100%;
  margin: 0;
  padding: 2.5%;
  font-size: 0.7rem;
  font-weight: 600;
  text-align: center;
}

.export > p > a {
  text-decoration: none;
  color: #000000;
}

.rolesTableMain table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 0% !important;
  table-layout: fixed;
  margin-left: 0 !important;
}

.rolesTableMain th,
td {
  text-align: center !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 0.7rem;
}

.rolesTableMain thead tr {
  box-shadow: 0px 0px 4px 0px #ff99144d !important;
  border-radius: 8px;
}

.rolesTableMain tbody tr {
  box-shadow: 0px 0px 2px 0px #999fa980;
  border-radius: 8px;
}

.rolesTableMain tr {
  margin-bottom: 10px !important;
}

.rolesTableMain td {
  width: 12%;
  max-width: 25%;
  padding: 1.5%;
}

.rolesTableMain th {
  width: 12%;
  max-width: 25%;
  padding: 1%;
  font-size: 0.7rem;
  font-weight: 500;
  letter-spacing: 0.4px;
  color: #ff9914;
  position: relative;
}

.rolesTableMain th:not(:last-child)::after {
  content: "";
  position: absolute;
  top: 25%;
  right: 0px;
  bottom: 0;
  width: 2px;
  height: 3dvh;
  background-color: #e6e6e6 !important;
}

.footer {
  width: 98%;
  margin-left: 1%;
  display: flex;
  justify-content: space-between;
  margin-top: 2%;
}
.action {
  display: flex;
  width: 50%;
  align-items: center;
  padding-bottom: 2%;
  gap: 2%;
}

.actionButton {
  width: 20%;
  text-align: center;
  padding: 1.5%;
  border-radius: 22px;
  border: 1px solid #999fa9;
  font-size: 0.8rem;
  transition: all 0.3s ease;
  cursor: pointer;
}

.activeButton {
  background-color: #ff9914;
  color: #ffffff;
  font-size: 0.8rem;
  border: none !important;
}

.page {
  width: 50%;
  text-align: right;
}

.page span {
  font-size: 0.8rem;
}

.page span:first-child {
  font-size: 0.8rem;
  padding: 0.3% 1.6%;
  border: 1px solid #00054f26;
  border-radius: 5px;
}

.actionTd img {
  width: 15px;
  height: 15px;
  margin-right: 15px;
  cursor: pointer;
}

.viewDetails {
  color: #ff9914;
  text-decoration-line: underline;
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .tableMain {
    width: 100%;
    padding: 6% 2%;
    border-radius: 8px;
  }

  .filterExport {
    gap: 1.5dvh;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0 0 1% 0;
  }

  .filterExport p {
    width: 100%;
    margin: 0;
  }

  .search {
    width: 100%;
  }

  .search input {
    background-size: 4%;
    height: 6.5dvh;
    padding: 3%;
  }

  .export {
    width: 100%;
  }

  .rolesTableMain {
    width: 100%;
    overflow-x: auto !important;
  }
  .rolesTableMain table {
    width: 300dvw !important;
    border-collapse: collapse;
    margin-top: 5%;
    table-layout: auto !important;
    margin-left: 1%;
  }

  .rolesTableMain td {
    width: 20dvw !important;
    max-width: 30dvw;
    height: 8dvh;
    padding: 0% !important;
  }

} */




.tableMain {
  width: 100%;
  margin-top: 2%;
  border-radius: 8px;
}

.checkbox {
  display: flex;
  justify-content: space-evenly;
}
.SNoCheckBox {
  display: flex;
  justify-content: space-evenly;
  margin-left: 9%;
}

.filterExport {
  width: 100%;
  display: flex;
  gap: 2%;
  justify-content: flex-end;
  padding: 0 0 1% 0;
}

.filterExport p {
  width: 50%;
  margin: 0;
  cursor: pointer;
}

.search {
  width: 13%;
}

.search input {
  width: 100%;
  background-color: #fff9f1;
  border-radius: 8px;
  background-image: url(../../../assets/images/searchIcon.png);
  background-repeat: no-repeat;
  background-size: 10%;
  background-position-x: 90%;
  background-position-y: 45%;
  padding: 3%;
  font-size: 0.8rem;
  outline: none;
  border: none;
}

.search input:focus {
  background-image: none;
}

.export {
  border: 1px solid #e6e6e6;
  border-radius: 15px;
  width: 20%;
  cursor: pointer;
}

.export p {
  width: 100%;
  margin: 0;
  padding: 2.5%;
  font-size: 0.7rem;
  font-weight: 600;
  text-align: center;
}

.export > p > a {
  text-decoration: none;
  color: #000000;
}

.actionTd img {
  width: 15px;
  height: 15px;
  margin-right: 15px;
  cursor: pointer;
}

.viewDetails {
  color: #ff9914;
  text-decoration-line: underline;
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .tableMain {
    width: 100%;
    padding: 6% 2%;
    border-radius: 8px;
  }

  .filterExport {
    gap: 1.5dvh;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0 0 1% 0;
  }

  .filterExport p {
    width: 100%;
    margin: 0;
  }

  .search {
    width: 100%;
  }

  .search input {
    background-size: 4%;
    height: 6.5dvh;
    padding: 3%;
  }

  .export {
    width: 100%;
  }

}
