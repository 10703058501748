.main {
  width: 100%;
  border-radius: 8px;
  margin-top: -1%;
  padding-bottom: 2%;
}

.pageHeader {
  display: flex;
  justify-content: space-between;
  padding: 2% 2% 3% 0 !important;
  background-color: #fcfcfc;
}

.pageHeaderLeft {
  width: 60%;
}

.pageHeaderRight {
  width: 40%;
  display: flex;
  justify-content: flex-end;
  gap: 2%;
}
.pageHeaderLeft p {
  font-weight: 600;
  font-size: 1.1rem;
  padding-left: 0.5%;
  width: 100%;
}

.activePageStyle {
  color: #ff9914;
  margin-left: 1.5%;
}

.contentContainer {
  width: 97.5%;
  box-shadow: 0px 1px 8px 0px #00000026;
  margin-left: 1%;
  padding: 1%;
  border-radius: 12px;
  font-family: Poppins;
}

.fieldContainer {
  width: 100%;
  display: flex;
}

.feildLeft {
  width: 50%;
}
.feildRight {
  width: 50%;
}

.fieldLabel {
  margin-top: 3%;
}
.fieldLabel p {
  margin: 0;
  font-size: 0.8rem;
  font-weight: 500;
}
.fieldInput {
  width: 80%;
  margin-top: 2%;
}

.fieldInput input {
  background-color: #fff9f1;
  outline: none;
  border: none;
  width: 100%;
  height: 3rem;
  border-radius: 8px;
  padding-left: 2%;
  font-size: 0.8rem;
}
.fieldInput input::placeholder {
  font-size: 0.7rem !important;
  padding-left: 20px;
}

.fieldDate {
  width: 80%;
  display: flex;
  gap: 2%;
}
.fieldDate input {
  background-color: #fff9f1;
  outline: none;
  border: none;
  width: 100%;
  height: 3rem;
  border-radius: 8px;
  padding-left: 2%;
  font-size: 0.8rem;
}

.footer {
  margin-top: 2%;
  display: flex;
  gap: 2%;
  padding-bottom: 2%;
}
.footer button {
  font-weight: 400;
  font-size: 0.7rem;
  color: #ffffff;
  background: #ff9914;
  border: none;
  width: 10%;
  padding: 0 2%;
  height: 5.5dvh;
  border-radius: 18px;
}

.footer button:first-child {
  color: #000000;
  background-color: #ffffff;
  border: 1px solid #95a1bb;
}

input[type="date"] {
  appearance: none !important;
}

input[type="date"]:focus {
  outline: none;
}

.regionDropdown {
  width: 100%;
}

select {
  width: 100%;
  min-height: 10px;
  height: 3rem;
  border-radius: 8px;
  border: none;
  box-shadow: none;
  font-size: 0.7rem;
  background: #fff9f1;
  font-weight: 400;
  padding-left: 14px;
}

.poojaTypeContainer {
  border-radius: 6px;
  /* border: 2px solid #ede8e8; */
  width: 90%;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 0.5rem;
}

.poojaTypeInputGroup {
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: space-between;
  flex-wrap: wrap;
}

.poojaTypeLabel {
  font-size: 16px;
  margin-right: 20px;
  margin-top: 1rem;
  color: black;
  white-space: nowrap;
  flex: 0 0 auto;
}

.poojaTypeInputWrapper {
  display: flex;
  align-items: center;
  margin-right: 20px;
  flex: 1;
  min-width: 120px;
}

.poojaTypeInputLabel {
  margin-right: 10px;
  color: black;
  white-space: nowrap;
}

.poojaTypeInput {
  padding: 5px;
  border-radius: 6px;
  border: none;
  background-color: #fff9f1;
  outline: none;
  width: 100%;
  box-sizing: border-box;
}

.poojaTypeDeleteBtn {
  padding: 5px 10px;
  color: white;
  background-color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  white-space: nowrap;
  flex-shrink: 0;
  width: 3% !important;
  background-image: url(../../../../../assets/images/deleteIcon.png) !important;
  background-size: 20px;
  background-repeat: no-repeat;
  background-position-x: 100% !important;
  background-position-y: 50%;
  height: 5vh;
}
.addMore {
  background-color: orange;
  color: white;
  cursor: pointer;
}
.more {
  cursor: pointer;
}

.fieldInput textarea {
  background-color: #fff9f1;
  outline: none;
  border: none;
  width: 100%;
  height: 5rem;
  border-radius: 8px;
  resize: none;
  padding-left: 3%;
  font-size: 0.9rem;
  padding: 5px;
}

@media (max-width: 768px) {
  .poojaTypeContainer {
    flex-direction: column;
    align-items: flex-start;
  }

  .poojaTypeInputGroup {
    flex-direction: column;
    width: 100%;
  }

  .poojaTypeInputWrapper {
    flex: 1;
    width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
  }

  .poojaTypeInput {
    width: 100%;
  }

  .poojaTypeDeleteBtn {
    align-self: flex-end;
    margin-top: 10px;
    height: 5vh;
    width: 3% !important;
    background-image: url(../../../../../assets/images/deleteIcon.png) !important;
    background-size: 20px;
    background-repeat: no-repeat;
    background-position-x: 100% !important;
    background-position-y: 50%;
  }
}

@media screen and (max-width: 767px) {
  .footer button:first-child {
    color: #000000;
    background-color: #ffffff;
    border: 1px solid #95a1bb;
    width: 25%;
  }
  .footer button {
    width: 25%;
  }
}
