.main {
  width: 100%;
  font-family: Poppins;
  padding-bottom: 2%;
}

.contentContainer {
  width: 97.5%;
  background: #ffffff;
  margin-left: 1%;
  padding: 1%;
  border-radius: 12px;
  font-family: Poppins;
}

.basicInfo {
  width: 100%;
  display: flex;
  gap: 1%;
}

.basicInfoSectionLeft {
  width: 49.5%;
  display: flex;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  padding: 1%;
}
.basicInfoSectionRight {
  width: 49.5%;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  padding: 1%;
}

.profilePic {
  width: 25%;
}
.profilePic > p {
  margin: 0;
  font-size: 1rem;
  font-weight: 600;
}

.profilePic img {
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  margin: auto;
  display: block;
}

.leftBasicInfo {
  width: 75%;
  padding-top: 8%;
}

.singleBasicInfo {
  width: 100%;
  display: flex;
  padding-bottom: 2%;
}

.singleBasicInfo > p:first-child {
  width: 35%;
  margin: 0;
  font-size: 0.9rem;
  font-weight: 600;
}
.singleBasicInfo > p:last-child {
  width: 65%;
  margin: 0;
  font-size: 0.85rem;
  font-weight: 500;
}

.email {
  display: flex;
  width: 60%;
  justify-content: flex-start;
  align-items: center;
  gap: 5%;
  margin-top: 4%;
}
.phone {
  display: flex;
  width: 40%;
  justify-content: flex-start;
  align-items: center;
  gap: 5%;
  margin-top: 4%;
}
.email img {
  width: 12%;
}

.email p {
  margin: auto 0;
  font-size: 0.65rem;
}
.phone img {
  width: 17%;
}
.phone p {
  margin: auto 0;
  font-size: 0.65rem;
}

.basicInfoSectionRight > p {
  margin: 0;
  padding-bottom: 3%;
  font-weight: 600;
}

.bioContent {
  width: 100%;
  padding: 1%;
  margin-top: 2%;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
}

.bioContent p:first-child {
  font-size: 1rem;
  font-weight: 600;
  margin: 0;
}

.bioContent p:last-child {
  font-size: 0.8rem;
  margin: 0;
  padding-top: 0.5%;
  color: #707070;
}

.personalDetails {
  width: 100%;
  display: flex;
  margin-top: 2%;
  gap: 1%;
}

.abhiruchi {
  width: 22%;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  padding: 1%;
}

.abhiruchi > p {
  margin: 0;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 2%;
}

.abhiruchiRow {
  display: flex;
  flex-wrap: wrap;
}

.abhiruchiRow > p {
  display: inline-block;
  text-align: center;
  /* flex: 1 1 calc(33.33% - 10px); */
  width: 45%;
  margin: 5px;
  font-size: 0.75rem;
  font-weight: 500;
  padding: 1%;
  border-radius: 8px;
  background-color: #f4f4f4;
}

.placesLived {
  width: 22%;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  padding: 1%;
}

.placesLived > p {
  margin: 0;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 2%;
}

.placesLivedSingle {
  display: flex;
  margin-bottom: 2%;
  justify-content: space-between;
  padding: 0 4% 0 0;
}

.placesLivedSingle p:first-child {
  margin: 0;
  font-size: 0.75rem;
  font-weight: 600;
  margin-top: 3%;
  width: 40%;
}
.placesLivedSingle p:last-child {
  margin: 0;
  margin-top: 3%;
  font-size: 0.7rem;
}

.otherPlacesLived {
  width: 60%;
  display: flex;
  justify-content: flex-end;
  max-width: 60%;
  overflow-x: auto;
}
.otherPlacesLived p:first-child {
  margin: 0;
  font-size: 0.75rem;
  width: 60%;
  max-width: 60%;
  overflow-x: auto;
}

.otherPlacesLived p:last-child {
  margin: 0;
  margin-top: 3%;
  font-size: 0.7rem;
}

.familyMembers {
  width: 26.5%;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  padding: 1%;
}

.familyMembersHeading {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 2%;
  gap: 5%;
}

.familyMembersHeading > p {
  margin: 0;
  font-size: 1rem;
  font-weight: 600;
}

.familyMembersHeading button {
  width: 17%;
  border: none;
  background: transparent;
  font-size: 0.9rem;
  background-image: url(../../../assets/images/addMore.png);
  background-size: 15px;
  background-repeat: no-repeat;
  background-position-x: 13%;
  background-position-y: 50%;
}

.userDetailBoxContainer {
  max-height: 15dvh;
  overflow-y: auto;
}

.singleMember {
  flex: 1 1 calc(49.5% - 10px);
  margin: 5px;
}
.singleMember > p:first-child {
  font-size: 0.75rem;
  font-weight: 500;
  margin: 0;
}
.singleMember > p:last-child {
  font-size: 0.7rem;
  margin: 0;
  color: #707070;
}

.education {
  width: 26.5%;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  padding: 1%;
}

.education > p {
  margin: 0;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 2%;
}

.educationRow {
  width: 100%;
}

.educationRow:not(:last-child) {
  width: 80%;
  border-bottom: 1px solid #e3e3e3;
}

.educationRow p:nth-child(1) {
  font-size: 0.75rem;
  font-weight: 500;
  margin: 0;
}
.educationRow p:nth-child(2),
.educationRow p:nth-child(3) {
  font-size: 0.7rem;
  margin: 0;
  color: #707070;
}

.work {
  width: 20%;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  padding: 1%;
}

.work > p {
  margin: 0;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 2%;
}

.workRow {
  width: 100%;
}

.workRow:not(:last-child) {
  width: 90%;
  border-bottom: 1px solid #e3e3e3;
}

.workRow p:nth-child(1) {
  font-size: 0.75rem;
  font-weight: 500;
  margin: 0;
}
.workRow p:nth-child(2),
.workRow p:nth-child(3) {
  font-size: 0.65rem;
  margin: 0;
  color: #707070;
}

.daiva {
  width: 52.5%;
  overflow-x: auto;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  padding: 1%;
  display: flex;
  flex-direction: column;
  gap: 2dvh;
  padding-right: 5%;
}

.singleDaivaRow {
  width: 100%;
  display: flex;
}

.singleDaivaRow > p {
  width: 30%;
  margin: 0;
  font-size: 1rem;
  font-weight: 600;
  margin: auto 0;
}

.singleDaivaContainer {
  width: 70%;
  display: flex;
  gap: 10%;
}

/* .daivaRow{
width: 80%;
display: flex;
} */

.singleDaiva {
  display: flex;
  /* width: 40%; */
  align-items: center;
  gap: 10px;
}

.singleDaiva img {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
}
.singleDaiva p {
  margin-right: 1rem;
  margin-top: 1rem;

  font-size: 0.75rem;
  font-weight: 600;
}

.assistedBy {
  width: 26.5%;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  padding: 1%;
}

.assistedBy > p {
  margin: 0;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 2%;
}

.assistedByRow {
  width: 100%;
  display: flex;
}

.assistedByProfile {
  width: 20%;
}
.assistedByProfile img {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
}

.assistedByDetails {
  width: 80%;
}

.assistedByDetails p:first-child {
  margin: 0;
  font-size: 0.65rem;
  font-weight: 400;
  color: #808080;
}
.assistedByDetails p:last-child {
  margin: 0;
  font-size: 0.7rem;
  font-weight: 500;
  color: #000000;
}

.assistedByRowTwo {
  display: flex;
  flex-direction: column;
  gap: 5%;
  margin-top: 5%;
}

.assistedByDetailsTwo {
  display: flex;
  padding-left: 20%;
  gap: 5%;
  margin-top: 2%;
}

.assistedByDetailsTwo img {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
}

.assistedByDetailsTwo p {
  margin: 0;
  font-size: 0.7rem;
}

@media (max-width:767px){
  .profilePic {
    width: 100%!important;
  }
  .basicInfoSectionLeft,.basicInfoSectionRight {
    width: 100%;
    display: block;
    margin-bottom: 10px;
    padding: 10px;
  }
  .basicInfo,.personalDetails{
    display: block;
  }
  .abhiruchi,.placesLived,.education,.familyMembers,.work,.daiva,.assistedBy{
    width: 100%;
    margin-bottom: 10px;
    padding: 10px;
  }
  .main {
    margin-top: 40px;
  }
  .singleBasicInfo > p:first-child,.singleBasicInfo > p:last-child,.placesLived > p,.singleDaivaRow > p,.singleDaivaContainer span,.userDetailBoxContainer span,.familyMembersHeading > p,.education > p,.work > p,.abhiruchiRow > div,.abhiruchi > p,.bioContent p:first-child,.bioContent p:last-child {
    font-size: 13px;
  }
  .profilePic img{
    width: 65px;
    height: 65px;
  }

}