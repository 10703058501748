.main {
  width: 100%;
  /* padding-bottom: 2%; */
  padding: 0 1%;
}

.pageHeader {
  display: flex;
  justify-content: space-between;
  padding: 2% 2% 3% 0;
  background-color: #fcfcfc;
  font-family: Poppins;
}

.pageHeader p {
  font-weight: 600;
  font-size: 1.1rem;
  padding-left: 1.3%;
}
.pageHeader button {
  font-weight: 400;
  font-size: 0.7rem;
  color: #ffffff;
  background-color: #95a1bb;
  border: none;
  padding: 0 3%;
  height: 5.5dvh;
  border-radius: 8px;
}

.cardMain {
  width: 100%;
  display: flex;
  padding: 0 1%;
  /* margin-top: -2.5%; */
  z-index: 1;
  background-color: #fff;
}

.cardLeftMain {
  width: 50%;
  display: flex;
  gap: 2%;
}

.cardRightMain {
  width: 50%;
  padding-left: 1.5%;
}

.singleCardLeft {
  width: 32%;
  display: flex;
  box-shadow: 0px 1px 8px 0px #00000026;
  padding: 2% 0.5%;
  border-radius: 8px;
}

.cardIcon {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardIcon img {
  width: 90%;
}

.cardContent {
  width: 70%;
  display: flex;
  flex-direction: column;
  padding-left: 5%;
  text-align: center;
  padding: 2.5%;
}

.cardContent p:nth-child(1) {
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0;
  padding-top: 5%;
}

.cardContent p:nth-child(2) {
  font-size: 0.8rem;
  font-weight: 500;
  margin: 0;
}
.cardContent p:nth-child(3) {
  font-size: 0.8rem;
  font-weight: 500;
  margin: 0;
}

.cardRightBox {
  width: 98%;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 8px 0px #00000026;
  border-radius: 8px;
  padding: 1%;
  gap: 0.5%;
}

.cardRightHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 2% 2%;
}
.cardRightHeader p:nth-child(1) {
  font-size: 0.7rem;
  font-weight: 400;
  color: #000000;
  margin: 0;
  cursor: pointer;
}
.cardRightHeader p:nth-child(2) {
  font-size: 0.7rem;
  font-weight: 400;
  margin: 0;
  cursor: pointer;
}

.cardRightContainer {
  width: 100%;
  display: flex;
  gap: 1%;
}

.singleCardRight {
  width: 32%;
  display: flex;
  background: #f6f3ff;
  border-radius: 8px;
}

.cardIconRight {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardIconRight img {
  width: 70%;
}

.cardContentRight {
  width: 70%;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding-top: 20%;
  padding: 0% 1%;
}

.cardContentRight p:nth-child(1) {
  font-size: 1rem;
  font-weight: 600;
  margin: 0;
  padding-top: 8%;
}

.cardContentRight p:nth-child(2) {
  font-size: 0.7rem;
}

.kycManagementTable {
  width: 97%;
  margin-left: 1%;
  margin-top: 2%;
  box-shadow: 0px 1px 8px 0px #00000026;
  border-radius: 8px;
}

.filterHeader {
  display: flex;
  width: 100%;
}

.radioFilter {
  width: 70%;
  display: flex;
  gap: 5%;
  align-items: center;
  padding: 2%;
}

.radioOne {
  width: 10%;
  display: flex;
  gap: 26%;
}
.radioTwo {
  width: 16%;
  display: flex;
  gap: 14%;
}
.radioThree {
  width: 29%;
  display: flex;
  gap: 8%;
}
.radioFour {
  width: 22%;
  display: flex;
  gap: 12%;
}

label {
  font-size: 0.8rem;
  color: #707070;
  font-weight: 500;
}

.activeRadio {
  color: #ff9914;
}

.radioStyle {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 2px solid #ff9914;
  position: relative;
}

.radioStyle::before {
  content: "";
  width: 10px;
  height: 10px;
  background-color: transparent;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.radioStyle:checked::before {
  background-color: #ff9914;
}

.filterExport {
  width: 30%;
  display: flex;
  gap: 2%;
  align-items: center;
  padding: 1%;
}

.export {
  border: 1px solid #e6e6e6;
  border-radius: 15px;
  width: 100%;
}

.export p {
  margin: 0;
  padding: 2.5%;
  font-size: 0.7rem;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
}

.export > p > a {
  text-decoration: none;
  color: #000000;
}

.filterDiv {
  border: 1px solid #e6e6e6;
  border-radius: 15px;
  width: 30%;
}

.filterDiv p {
  margin: 0;
  padding: 5% 0;
  font-size: 0.7rem;
  font-weight: 600;
  text-align: center;
  background-image: url(../../assets/images/filter.png);
  background-size: 14px;
  background-position-x: 18%;
  background-position-y: 50%;
  background-repeat: no-repeat;
  cursor: pointer;
}

.searchKYC {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 0 2% 1% 0;
}

.searchKYC input {
  width: 30%;
  background-color: #fff9f1;
  border-radius: 15px;
  background-image: url(../../assets/images/searchIcon.png);
  background-repeat: no-repeat;
  background-size: 5%;
  background-position-x: 90%;
  background-position-y: 45%;
  padding: 1%;
  margin-top: 2%;
  font-size: 0.8rem;
  outline: none;
  border: none;
}

.searchKYC input:focus {
  background-image: none;
}

.tableMainContainer table {
  width: 98%;
  border-collapse: collapse;
  margin-top: 5%;
  table-layout: fixed;
  margin-left: 1%;
}

.tableMainContainer th,
td {
  text-align: center !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 0.8rem;
  width: auto !important; /* Set width to auto to allow each column to adjust */
  max-width: none !important; /* Reset max-width to allow full width */
  padding: 1% 0 !important;
}

.tableMainContainer thead tr {
  box-shadow: 0px 0px 4px 0px #ff99144d !important;
  border-radius: 8px;
}

.tableMainContainer tbody tr {
  box-shadow: 0px 0px 2px 0px #999fa980;
  border-radius: 8px;
}

.tableMainContainer tr {
  margin-bottom: 10px !important;
}

.tableMainContainer td {
  width: 25%;
  max-width: 25%;
  height: 8dvh;
  padding: 0% !important;
}

.tableMainContainer th {
  padding: 1%;
  font-size: 0.8rem;
  font-weight: 500;
  letter-spacing: 0.4px;
  color: #ff9914;
  position: relative;
}

.tableMainContainer th:not(:last-child)::after {
  content: "";
  position: absolute;
  top: 25%;
  right: 0px;
  bottom: 0;
  width: 2px;
  height: 3dvh;
  background-color: #e6e6e6 !important;
}

.kycViewDetails {
  color: #ff9914;
  text-decoration-line: underline;
  cursor: pointer;
}

.footer {
  width: 98%;
  margin-left: 1%;
  display: flex;
  justify-content: space-between;
  margin-top: 2%;
  /* padding-bottom: 3%; */
}
.action {
  display: flex;
  width: 50%;
  align-items: center;
  padding-bottom: 2%;
  gap: 2%;
}

.actionButton {
  width: 20%;
  text-align: center;
  padding: 1.5%;
  border-radius: 22px;
  border: 1px solid #999fa9;
  font-size: 0.8rem;
  transition: all 0.3s ease;
  cursor: pointer;
}

.activeButton {
  background-color: #ff9914;
  color: #ffffff;
  font-size: 0.8rem;
  border: none !important;
}

.page {
  width: 50%;
  text-align: right;
}

.page span {
  font-size: 0.8rem;
}

.page span:first-child {
  font-size: 0.8rem;
  padding: 0.3% 1.6%;
  border: 1px solid #00054f26;
  border-radius: 5px;
}

.statusText {
  width: 88%;
  margin: 0 auto;
  padding: 4% 0%;
  font-size: 0.7rem;
  font-weight: 600;
  border-radius: 15px;
}

.panditProfileStatus {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  gap: 2%;
}

.panditProfileStatus > p {
  margin: 0;
  display: inline-block;
  padding: 4% 8%;
  background: #ff9914;
  border-radius: 10px;
  font-size: 0.75rem;
}
.panditProfileStatus > p > span > img {
  width: 8px;
  height: 5px;
}

@media screen and (max-width: 767px) {
  .searchKYC input {
    width: 100%;
  }
  .ant-modal-root .ant-modal {
    max-width: 100% !important;
    margin: 0px auto !important;
    width: 100% !important;
}
  /* .pageHeader {
    padding: 8% 2% 3% 2%;
    align-items: center;
  }
  
  .pageHeader p {
    font-size: 0.85rem;
    margin-bottom: 0;
  }
  .pageHeader button {

  } */

  .pageHeader {
    flex-direction: column;
    padding: 4% 2% !important;
  }

  .panditProfileManagement_pageHeader__m8RCm {
    padding-top: 10px !important;
}

  .pageHeader p {
    font-size: 1rem;
    margin-bottom: 0;
  }

  .pageHeader button {
    width: 43%;
    padding: 0 3.5%;
    height: 6.5dvh;
    margin-top: 3%;
  }

  .cardMain {
    flex-direction: column;
    margin-top: 2%;
  }

  .cardLeftMain {
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 2svh;
  }

  .cardRightMain {
    width: 100%;
    padding-left: 1.5%;
  }

  .singleCardLeft {
    width: 85%;
    display: flex;
    box-shadow: 0px 1px 8px 0px #00000026;
    padding: 2%;
    border-radius: 8px;
  }

  .cardIcon {
    width: 30%;
  }

  .cardIcon img {
    width: 60%;
  }

  .cardContent {
  }

  .cardContent p:nth-child(1) {
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0;
    padding-top: 5%;
  }

  .cardContent p:nth-child(2) {
    font-size: 0.8rem;
    font-weight: 500;
    margin: 0;
  }
  .cardContent p:nth-child(3) {
    font-size: 0.8rem;
    font-weight: 500;
    margin: 0;
  }

  .cardRightBox {
    margin-top: 2%;
  }

  .cardRightHeader {
  }
  .cardRightHeader p:nth-child(1) {
  }
  .cardRightHeader p:nth-child(2) {
  }

  .cardRightContainer {
    flex-direction: column;
    align-items: center;
    gap: 2svh;
  }

  .singleCardRight {
    width: 85%;
  }

  .cardIconRight {
  }

  .cardIconRight img {
    width: 50%;
  }

  .kycManagementTable {
    width: 97%;
    margin-left: 1%;
    margin-top: 2%;
    box-shadow: 0px 1px 8px 0px #00000026;
    border-radius: 8px;
  }

  .filterHeader {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 2svh;
  }

  .radioFilter {
    width: 100%;
    flex-direction: column;
    gap: 2svh;
  }
  .radioOne {
    width: 100%;
    gap: 5%;
    padding-left: 20%;
  }
  .radioTwo {
    width: 100%;
    gap: 5%;
    padding-left: 20%;
  }
  .radioThree {
    width: 100%;
    gap: 5%;
    padding-left: 20%;
  }
  .radioFour {
    width: 100%;
    gap: 5%;
    padding-left: 20%;
  }

  label {
    font-size: 0.7rem;
    color: #707070;
    font-weight: 500;
  }

  .activeRadio {
    color: #ff9914;
  }

  .radioStyle {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 2px solid #ff9914;
    position: relative;
  }

  .radioStyle::before {
    content: "";
    width: 10px;
    height: 10px;
    background-color: transparent;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .radioStyle:checked::before {
    background-color: #ff9914;
  }

  /* .filterExport {
    width: 100%;
  } */

  /* .searchKYC {
    width: 100%;
    margin-top: 2%;
    display: flex;
    justify-content: center;    
    padding: 0 1% 1% 0;
  } */

  /* .searchKYC input {
    width: 50%;
  } */

  .filterExport {
    width: 100%;
    gap: 2dvh;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 1% 2%;
  }

  .filterExport p {
    width: 100%;
    margin: 0;
  }

  .searchKYC {
    width: 100%;
    margin-top: 2dvh;
    padding: 2%;
  }

  .searchKYC input {
    background-size: 4%;
    height: 6.5dvh;
    padding: 3%;
  }

  .export {
    width: 100%;
  }

  .filterDiv {
    width: 50%;
  }

  .filterDiv p {
    background-size: 10%;
    background-position-x: 28%;
  }

  .tableMainContainer {
    width: 100%;
    overflow-x: auto !important;
  }
  .tableMainContainer table {
    width: 300dvw !important;
    border-collapse: collapse;
    margin-top: 5%;
    table-layout: auto !important;
    margin-left: 1%;
  }

  td {
    width: 20dvw !important;
    max-width: 30dvw;
    height: 8dvh;
    padding: 0% !important;
  }

  /* td:nth-child(1){
    width: 20dvw !important; 
  }
  td:nth-child(2){
    width: 20dvw !important; 
  }
  td:nth-child(3){
    width: 20dvw !important; 
  }
  td:nth-child(4){
    width: 20dvw !important; 
  } */
}
