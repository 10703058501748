.main {
  width: 100%;
  border-radius: 8px;
  margin-top: -1%;
  padding-bottom: 2%;
}

.contentContainer {
  width: 97.5%;
  box-shadow: 0px 1px 8px 0px #00000026;
  margin-left: 1%;
  padding: 1%;
  border-radius: 12px;
  font-family: Poppins;
}

.fieldContainer {
  width: 100%;
  display: flex;
}

.feildLeft {
  width: 50%;
}
.feildRight {
  width: 50%;
}

.fieldLabel {
  margin-top: 3%;
}
.fieldLabel p {
  margin: 0;
  font-size: 0.8rem;
  font-weight: 500;
}
.fieldInput {
  width: 80%;
  margin-top: 2%;
}

.fieldInput input {
  background-color: #fff9f1;
  outline: none;
  border: none;
  width: 100%;
  height: 3rem;
  border-radius: 8px;
  padding-left: 2%;
  font-size: 0.8rem;
}
.error {
  color: red;
  font-size: 0.7rem;
  margin-top: 0.4rem;
}
.fieldInput input::placeholder {
  font-size: 0.7rem !important;
  padding-left: 20px;
}

.fieldDate {
  width: 80%;
  display: flex;
  gap: 2%;
}
.fieldDate input {
  background-color: #fff9f1;
  outline: none;
  border: none;
  width: 100%;
  height: 3rem;
  border-radius: 8px;
  padding-left: 2%;
  font-size: 0.8rem;
}

.footer {
  margin-top: 2%;
  display: flex;
  gap: 2%;
  padding-bottom: 2%;
}
.footer button {
  font-weight: 400;
  font-size: 0.7rem;
  color: #ffffff;
  background: #ff9914;
  border: none;
  width: 10%;
  padding: 0 2%;
  height: 5.5dvh;
  border-radius: 18px;
}

.footer button:first-child {
  color: #000000;
  background-color: #ffffff;
  border: 1px solid #95a1bb;
}

input[type="date"] {
  appearance: none !important;
}

input[type="date"]:focus {
  outline: none;
}

.regionDropdown {
  width: 100%;
}

select {
  width: 100%;
  min-height: 10px;
  height: 3rem;
  border-radius: 8px;
  border: none;
  box-shadow: none;
  font-size: 0.7rem;
  background: #fff9f1;
  font-weight: 400;
  padding-left: 14px;
}

@media screen and (max-width: 767px) {
  .footer button:first-child {
    color: #000000;
    background-color: #ffffff;
    border: 1px solid #95a1bb;
    width: 25%;
  }
  .footer button {
    width: 25%;
  }
}
