.main {
    width: 100%;
    font-family: Poppins;
    padding-bottom: 2%;
  }
  
  
  .contentContainer {
    width: 97.5%;
    box-shadow: 0px 1px 8px 0px #00000026;
    margin-left: 1%;
    padding: 1%;
    border-radius: 12px;
    font-family: Poppins;
  }
  .activePage {
    margin-top: 3%;
  }
  
  .createContainer {
    width: 97%;
    border-radius: 8px;
    margin-top: -1%;
  }
  
  .fieldContainer {
    width: 100%;
    display: flex;
  }
  
  .feildLeft {
    width: 50%;
  }
  .feildRight {
    width: 50%;
  }
  
  .fieldLabel {
    margin-top: 3%;
  }
  .fieldLabel p {
    margin: 0;
    font-size: 0.8rem;
    font-weight: 500;
  }
  .fieldInput {
    width: 80%;
    margin-top: 2%;
  }
  
  .fieldInput input {
    background-color: #fff9f1;
    outline: none;
    border: none;
    width: 100%;
    height: 3rem;
    border-radius: 8px;
  }
  
  .fileInput {
    background-color: #fff9f1;
    outline: none;
    border: none;
    width: 100%;
    height: 3rem;
    border-radius: 8px;
  }
  
  .fieldInput textarea {
    background-color: #fff9f1;
    outline: none;
    border: none;
    width: 100%;
    height: 5rem;
    border-radius: 8px;
    resize: none;
  }
  
  .uploadIcon {
    margin-top: 2%;
    margin-left: 90%;
  }
  
  .fieldDate {
    width: 80%;
    display: flex;
    gap: 2%;
  }
  .fieldDate input {
    background-color: #fff9f1;
    outline: none;
    border: none;
    width: 100%;
    height: 3rem;
    border-radius: 8px;
  }
  
  .footer {
    margin-top: 5%;
  }
  
  input[type="text"]::placeholder {
    font-size: 0.7rem;
    padding-left: 20px;
  }
  
  input[type="date"] {
    appearance: none !important;
  }
  
  input[type="date"]:focus {
    outline: none;
  }
  
  @media screen and (max-width: 767px) {
    .fieldContainer {
      display: block;
    }
    .feildLeft {
      width: 100%;
    }
    .feildRight {
      width: 100%;
    }
    .fieldInput {
      width: 100%;
      margin-top: 2%;
      display: flex;
      justify-content: center;
    }
  }
  