.main {
  width: 100%;
  font-family: Poppins;
  padding-bottom: 2%;
}

.contentContainer {
  width: 97.5%;
  box-shadow: 0px 1px 8px 0px #00000026;
  margin-left: 1%;
  padding: 1%;
  border-radius: 12px;
  font-family: Poppins;
}
.samhitaContainer {
  width: 97%;
  border-radius: 8px;
  padding-top: 1%;
  padding-left: 1%;
}

.fieldContainer {
  width: 100%;
  display: flex;
}

.feildLeft {
  width: 50%;
}
.feildRight {
  width: 50%;
}

.fieldLabel {
  margin-top: 3%;
}
.fieldLabel p {
  margin: 0;
  font-size: 0.8rem;
  font-weight: 500;
}
.fieldInput {
  width: 80%;
  margin-top: 2%;
}

.fieldInput input {
  background-color: #fff9f1;
  outline: none;
  border: none;
  width: 100%;
  height: 3rem;
  border-radius: 8px;
  padding-left: 2%;
  font-size: 0.8rem;
}

.footer {
  margin-top: 5%;
}

input[type="text"]::placeholder {
  font-size: 0.7rem;
  padding-left: 20px;
}

.error {
  margin: 0;
  color: red;
  font-size: 0.7rem;
  margin-top: 0.5rem;
}

@media screen and (max-width: 767px) {
  .fieldContainer {
    display: block;
  }
  .feildLeft {
    width: 100%;
  }
  .feildRight {
    width: 100%;
  }
  .fieldInput {
    width: 100%;
    margin-top: 2%;
    display: flex;
    justify-content: center;
  }
}
