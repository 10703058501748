.main {
  width: 100%;
  font-family: Poppins;
  padding-bottom: 2%;
}

.activePageStyle {
  color: #ff9914;
  margin-left: 1.5%;
}

.contentContainer {
  width: 97.5%;
  box-shadow: 0px 1px 8px 0px #00000026;
  margin-left: 1%;
  padding: 1%;
  border-radius: 12px;
  font-family: Poppins;
}

.activePage {
  margin-top: 3%;
}

.tableContainerCommittee {
  width: 99%;
  border-radius: 8px;
}

.tableContainerCommittee > table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 0% !important;
  table-layout: auto;
  margin-left: 1%;
}

.tableContainerCommittee th,
td {
  text-align: center !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis !important;
  font-size: 0.8rem;
}

.tableContainerCommittee thead tr {
  box-shadow: 0px 0px 4px 0px #ff99144d !important;
  border-radius: 8px;
}

.tableContainerCommittee tbody tr {
  box-shadow: 0px 0px 2px 0px #999fa980;
  border-radius: 8px;
}

.tableContainerCommittee tr {
  margin-bottom: 10px !important;
}

.tableContainerCommittee td {
  max-width: 60%;
  padding: 1.5%;
}

.tableContainerCommittee th {
  padding: 1%;
  font-size: 0.8rem;
  font-weight: 500;
  letter-spacing: 0.4px;
  color: #ff9914;
  position: relative;
}

.tableContainerCommittee th:not(:last-child)::after {
  content: "";
  position: absolute;
  top: 25%;
  right: 0px;
  bottom: 0;
  width: 2px;
  height: 3dvh;
  background-color: #e6e6e6 !important;
}

.tableContainerCommittee td:nth-child(1) {
  width: 5dvw !important;
}

.tableContainerCommittee td:nth-child(2) {
  width: 25dvw !important;
}
.tableContainerCommittee td:nth-child(3) {
  width: 30dvw !important;
}
.tableContainerCommittee td:nth-child(4) {
  width: 10dvw !important;
}
.tableContainerCommittee td:nth-child(5) {
  width: 20dvw !important;
}
.tdIcon img {
  width: 15% !important;
  height: 15% !important;
}

 .footer {
  width: 98%;
  margin-left: 1%;
  display: flex;
  justify-content: space-between;
  margin-top: 2%;
}
 .action {
  display: flex;
  width: 50%;
  align-items: center;
  padding-bottom: 2%;
  gap: 2%;
}

 .actionButton {
  width: 20%;
  text-align: center;
  padding: 1.5%;
  border-radius: 22px;
  border: 1px solid #999fa9;
  font-size: 0.8rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

 .actionTd img {
  width: 15px;
  height: 15px;
  margin-right: 15px;
  cursor: pointer;
}

 .activeButton {
  background-color: #ff9914;
  color: #ffffff;
  font-size: 0.8rem;
  border: none !important;
}

 .page {
  width: 50%;
  text-align: right;
}
 .page span {
  font-size: 0.8rem;
}
 .page span:first-child {
  font-size: 0.8rem;
  padding: 0.3% 1.6%;
  border: 1px solid #00054f26;
  border-radius: 5px;
}

.tableContainerCommittee .tableviewDetails {
  color: #ff9914;
  text-decoration-line: underline;
  cursor: pointer;
}

.tableContainerCommittee .viewDetails {
  color: #ff9914;
  text-decoration-line: underline;
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .tableContainerCommittee {
    width: 100%;
    overflow-x: auto !important;
  }
  table {
    width: 180dvw !important;
    border-collapse: collapse;
    margin-top: 5%;
    table-layout: auto !important;
    margin-left: 1%;
  }
  .action{
    margin-top: 2%;
  }

  .actionButton {
    width: 50%;
    font-size: 0.7rem;
    padding: 4%;
  }
}
