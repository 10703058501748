.contentContainer {
  width: 98%;
  box-shadow: 0px 1px 8px 0px #00000026;
  margin-left: 1%;
  padding: 1%;
  border-radius: 12px;
  font-family: Poppins;
}
.menuContainer {
  width: 100%;
  display: flex;
  gap: 2%;
  padding: 1% 2%;
  font-family: Poppins;
}
.singleMenu {
  width: 18%;
  text-align: center;
  padding: 0.5% 0 0 0;
}
.singleMenu p {
  width: 100%;
  margin: 0;
  font-size: 0.75rem;
  color: #000000;
  cursor: pointer;
  text-decoration: none;
  position: relative;
}

.singleMenu p::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 100%;
  height: 1px;
  background-color: #999fa9;
}

.activeMenu {
  background-color: #ff991440;
}

.activeMenu p {
  color: #ff9914;
  font-family: Poppins;
  font-size: 0.75rem;
  font-weight: 600;
}

.activeMenu p::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100%;
  height: 1px;
  background-color: #ff9914;
}

.activePage {
  margin-top: 2%;
}

@media screen and (max-width: 767px) {
  .contentContainer {
    padding: 3% 1%;
  }

  .menuContainer{
    width: auto;
    gap: 3%;
    padding: 1% 0;
    overflow-x: auto;
    white-space: nowrap;
  }

  .singleMenu {
    width: 50%;
    text-align: center;
    padding: 2% 0 0 0;
  }

  .activeMenu {
    background-color: #ff991440;
  }

  .activeMenu p {
    color: #ff9914;
    font-family: Poppins;
    font-size: 0.8rem;
    font-weight: 600;
  }
  .activePage {
    margin-top: 2%;
  }
}
