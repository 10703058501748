.main {
  width: 100%;
  font-family: Poppins;
  padding-bottom: 2%;
}

.contentContainer {
  width: 97.5%;
  box-shadow: 0px 1px 8px 0px #00000026;
  margin-left: 1%;
  margin-top: -2%;
  padding: 1%;
  border-radius: 12px;
  font-family: Poppins;
}
