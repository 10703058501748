.singleDetailContainer{
    width: 100%;
    display: flex;
    font-family: Roboto;
}
.detailContainer{
    width: 50%;
    position: relative;
}

.selectAction{
 position: absolute;
 top: 10%;
 width: 96%;
display: flex;
justify-content: flex-end;
gap: 3%;
}

.detailsHeading{
    width: 100%;
}
.details{
    width: 100%;
    display: flex;
}
.profileImage{
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.profileImageCircle {
    width: 5.5rem;
    height: 5.5rem;
    border-radius: 50%;    
    overflow: hidden; 
}

.profileImageCircle img {
    object-position: center;
    width: 100%;
    height: 100%; 
}
.detailsContent{
    width: 80%;
    display: flex;
    flex-direction: column;
    padding-left: 4%;
}
.singleDetail{
    width: 100%;
    display: flex;
}
.singleDetailLeft{
    width: 35%;
}
.singleDetailRight{
    width: 65%;
    display: flex;
    justify-content: space-between;
}

.singleDetailRight > span {
    display: inline-block;
    width: 14%;
}

.singleDetailRight > span > img {
    width: 0.8rem;
}


.detailsHeading li{
    font-size: 0.8rem;
    font-weight: 600;
}

.singleDetail p{
    font-size: 0.8rem;
    margin: 0;
    padding-top: 2%;
}

.singleDetailLeft p{
    font-weight: 500;
}

@media (max-width:767px){
    .details{
        display: block;
    }
    .profileImage,.detailContainer,.detailsContent{
        width: 100%;
    }
    .detailContainer{
        padding: 0px!important;
    }
    .singleDetailContainer{
        display: block;
    }
    .pageHeaderRight button{
        padding: 10px;
    }
    .selectAction{
        position: relative;
        top: auto;
        justify-content: flex-start;
        padding-left: 12px;
        margin: 5px 0px;
    }
    .selectAction select{
        width: 100%!important;
        height: 30px!important;
    }
}