.main {
  width: 100%;
  font-family: Poppins;
  padding-bottom: 2%;
}

.pageHeader {
  display: flex;
  justify-content: space-between;
  padding: 2% 2% 3% 0 !important;
  background-color: #fcfcfc;
}

.pageHeaderLeft {
  width: 60%;
}

.pageHeaderRight {
  width: 40%;
  display: flex;
  justify-content: flex-end;
  gap: 2%;
}
.pageHeaderLeft p {
  font-weight: 600;
  font-size: 1.1rem;
  padding-left: 1.5%;
  width: 100%;
}

.activePageStyle {
  color: #ff9914;
  margin-left: 1.5%;
}
.pageHeaderRight button {
  font-weight: 400;
  font-size: 0.7rem;
  color: #ff9914;
  background: #ffebd0;
  border: none;
  width: 22%;
  padding: 0 2%;
  height: 5.5dvh;
  border-radius: 8px;
}

.contentContainer {
  width: 97.5%;
  background: #ffffff;
  margin-left: 1%;
  padding: 1%;
  border-radius: 12px;
  font-family: Poppins;
}

.panditMain {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.panditMain > p {
  font-size: 1rem;
  font-weight: 600;
  margin: 0;
}

.panditDetailBox {
  width: 23%;
  display: flex;
  flex-direction: row;
  padding: 1% 0;
  border: 1px solid #f3e6e6;
  border-radius: 0.5rem;
  margin-top: 1%;
}

.profileImage {
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profileImage img {
  height: 3rem;
  width: 2rem;
  border-radius: 50%;
}

.profileDetails {
  width: 70%;
  display: flex;
  flex-direction: column;
}

.profileImage > img {
  width: 3rem;
}

.profileDetails > p {
  font-size: 0.7rem;
  font-weight: 600;
  margin: 0;
}

.profileDetails > hr {
  width: 90%;
  margin: 0.5rem 0;
  height: 1px;
  color: #f3e6e6;
  opacity: 1;
}

.profileBasicDetails {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.profileBasicDetailsSingle {
  width: 50%;
  display: flex;
  flex-direction: row;
  gap: 4%;
  justify-content: center;
  align-items: center;
}

.profileBasicDetailsSingle > img {
  width: 0.8rem;
  height: 0.8rem;
}

.profileBasicDetailsSingle > p {
  font-size: 0.6rem;
  margin: 0;
}

.otherPanditMain {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.poojaDetailsContainer {
  background: #fcfcfc;
  width: 100%;
  border-radius: 0.8rem;
  display: flex;
  flex-direction: row;
  padding: 2%;
  margin-top: 4%;
}

.poojaDetailsMain {
  width: 50%;
}

.poojaPackageMain {
  width: 50%;
}

.poojaDetailsMain > p {
  font-size: 1rem;
  font-weight: 600;
  margin: 0;
}

.poojaPackageMain > p {
  font-size: 1rem;
  font-weight: 600;
  margin: 0;
}

.poojaDetails {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 4%;
}

.poojaDetailsLeft {
  width: 65%;
  display: flex;
  flex-direction: column;
  gap: 2dvh;
}
.poojaDetailsRight {
  width: 35%;
}

.singlePoojaDetail {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1dvh;
}

.singlePoojaDetail p:first-child {
  font-size: 0.85rem;
  font-weight: 600;
  margin: 0;
  color: #ff9914;
}
.singlePoojaDetail p:last-child {
  font-size: 0.75rem;
  font-weight: 500;
  /* margin: 0; */
  margin-bottom: 1rem;
}

.singleRowPooja {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 2%;
  margin-bottom: 1rem;
}

.singleRowPooja > img {
  width: 2rem;
}

.singleRowPooja > p {
  font-size: 0.75rem;
  margin: 0;
  margin: auto 0 !important;
}

.singleRowPoojaTwo {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 2%;
}

.singleRowPoojaTwo > img {
  width: 1.8rem;
  height: 1.8rem;
}

.singleRowPoojaTwo > p {
  font-size: 0.85rem !important;
  margin: 0;
  margin: auto 0 !important;
  color: #ff9914;
}

.pMarginLeft {
  margin: 0;
  margin-left: 11.5% !important;
}

.pBold {
  margin: 0;
  font-weight: 600;
  font-size: 0.75rem;
}

.devoteeList {
  display: flex;
  flex-direction: column;
}

.devoteeList > p {
  font-size: 0.75rem !important;
  color: #212529 !important;
  font-weight: normal !important;
}

.dakshinType {
  width: 85%;
  margin-top: 4%;
  display: flex;
}

.dakshinTypeLeft {
  width: 70%;
  display: flex;
  flex-direction: row;
  gap: 3%;
}

.dakshinTypeLeft img {
  width: 1.1rem;
  height: 1.1rem;
}

.dakshinTypeLeft p {
  font-size: 0.7rem;
  font-weight: 500;
  margin: 0;
}
.dakshinTypeRight p {
  font-size: 0.7rem;
  font-weight: 500;
  margin: 0;
}

.dakshinPricingMain {
  width: 70%;
  background: #e6e6e680;
  border-radius: 0.7rem;
  margin-top: 4%;
}

.singleDakshinPricing {
  display: flex;
  padding: 4%;
}

.singleDakshinPricing > p:first-child {
  font-size: 0.7rem;
  margin: 0;
  width: 50%;
}
.singleDakshinPricing > p:last-child {
  font-size: 0.7rem;
  font-weight: 500;
  margin: 0;
  width: 50%;
  text-align: right;
}

.dakshinPricingMain > hr {
  width: 95%;
  margin: 0 auto;
  color: white;
  height: 3px;
}

@media screen and (max-width: 767px) {
  .pageHeader {
    flex-direction: column;
    padding: 6% 2% !important;
  }

  .pageHeaderLeft {
    width: 100%;
  }

  .pageHeaderRight {
    width: 100%;
    display: flex;
    justify-content: space-around;
    gap: 3%;
  }

  .pageHeaderRight button {
    width: 43%;
    height: 6.5dvh;
  }

  .contentContainer {
    width: 97.5%;
    box-shadow: 0px 1px 8px 0px #00000026;
    margin-left: 1%;
    padding: 3% 1%;
    border-radius: 12px;
    font-family: Poppins;
  }

  .panditDetailBox {
    width: 95%;
    padding: 2% 0;
  }

  .poojaDetailsContainer {
    background: #fcfcfc;
    width: 100%;
    border-radius: 0.8rem;
    display: flex;
    flex-direction: column;
    padding: 2%;
    margin-top: 4%;
  }

  .poojaDetailsMain {
    width: 100%;
  }

  .poojaPackageMain {
    width: 100%;
  }

  .poojaDetails {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 4%;
  }

  .poojaDetailsLeft {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2dvh;
  }
  .poojaDetailsRight {
    width: 100%;
  }

  .singlePoojaDetail {
    width: 100%;
  }
  .singlePoojaDetail p {
    overflow-wrap: break-word;
  }

  .dakshinType {
    width: 95%;
    margin: 0 auto;
    margin-top: 4%;
  }

  .dakshinTypeLeft {
    width: 70%;
  }
  .dakshinTypeRight {
    width: 30%;
  }

  .dakshinTypeLeft > p {
    width: 100%;
    padding-left: 5%;
  }

  .dakshinTypeRight > p {
    width: 100%;
    padding-left: 28%;
  }

  .dakshinPricingMain {
    width: 90%;
    margin: 0 auto;
    margin-top: 4%;
  }

  .singleDakshinPricing {
    display: flex;
    padding: 4%;
  }

  .singleDakshinPricing > p:first-child {
    font-size: 0.7rem;
    margin: 0;
    width: 50%;
  }
  .singleDakshinPricing > p:last-child {
    font-size: 0.7rem;
    font-weight: 500;
    margin: 0;
    width: 50%;
    text-align: right;
  }

  .dakshinPricingMain > hr {
    width: 95%;
    margin: 0 auto;
    color: white;
    height: 3px;
  }
}
