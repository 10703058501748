.main {
    width: 100%;
    font-family: Poppins;
    padding-bottom: 2%;
  }
  
  .pageHeader {
    display: flex;
    justify-content: space-between;
    padding: 2% 2% 3% 0 !important;
    background-color: #fcfcfc;
  }
  
  .pageHeaderLeft {
    width: 60%;
  }
  
  .pageHeaderRight {
    width: 40%;
    display: flex;
    justify-content: flex-end;
    gap: 2%;
  }
  .pageHeaderLeft p {
    font-weight: 600;
    font-size: 1.1rem;
    padding-left: 1.5%;
    width: 100%;
    cursor: pointer;
  }
  
  .activePageStyle {
    color: #ff9914;
    margin-left: 1.5%;
  }
  .pageHeaderRight button {
    font-weight: 400;
    font-size: 0.7rem;
    color: #ffffff;
    background: #ff9914;
    border: none;
    width: 22%;
    padding: 0 2%;
    height: 5.5dvh;
    border-radius: 18px;
  }
  
  .pageHeaderRight button:first-child {
    color: #000000;
    background-color: #ffffff;
    border: 1px solid #95a1bb;
  }
  
  .contentContainer {
    width: 97.5%;
    box-shadow: 0px 1px 8px 0px #00000026;
    margin-left: 1%;
    padding: 1%;
    border-radius: 12px;
    font-family: Poppins;
  }

  
  .activePage {
    width: 100%;
    padding: 1% 2%;
  }

  .bannerImageSection{
    width: 30%;
    background-color: #FFF9F1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3%; 
  }
  

  .bannerImageSection img {
    width: 25%;
  }
  .bannerTitle p{
    font-size: 1.1rem;
    font-weight: 600;
    margin: 0;
  }

  .bannerLink{
    width: 100%;
    display: flex;
    gap: 10%;
  }

  .bannerLink p{
    font-size: 0.9rem;
  }

  .bannerLink p:first-child{
    font-weight: 500;
  }
  .bannerLink p:last-child{
    color: #5B5DFD;
    text-decoration: underline;
  }

  .bannerContent p{
    font-size: 1rem;
    margin: 0;
  }
  .bannerContent p:first-child{
    font-weight: 600;
  }
  .bannerContent p:last-child{
    font-size: 0.8rem;
    margin-top: 0.5%;
  }
  .bannerDetails{
    width: 100%;
    margin-top: 1%;
  }

  .bannerDetails > p{
    font-size: 1rem;
    margin: 0;
    font-weight: 600;
  }

  .singleDetails{
    display: flex;
    gap: 15%;
    margin-top: 1%;
  }
  .details{
    width: 40%;
    display: flex;
    justify-content: space-between;
  }

  .details p{
    margin: 0;
    font-size: 0.85rem;
  }

  .details p:first-child{
    font-weight: 600;
    width: 50%;
  }
  .details p:last-child{
    width: 50%;
    margin-left: 15%;
  }
  .copiedImgIcon {
    margin-left: 10px; /* Adjust the value to your desired spacing */
  }


  @media screen and (max-width: 767px) {
     .editBannerPara{
      margin-top: 15%;
    }
      .btnSaveDiscard{
        width:60% !important;
      }
      .pageHeader {
        display: block;
        justify-content: space-between;
        padding: 2% 2% 3% 0 !important;
        background-color: #fcfcfc;
      }
      .pageHeaderRight {
        margin-left: 1.5%;
         }
         .pageHeaderLeft p {
          font-weight: 600;
          font-size: 1.1rem;
          padding-left: 1.5%;
         
        }
        .singleDetails {
          display: block;
          margin-bottom: 5px;
        }

        .details p:last-child{
          margin-left: 0%;
        }
        .bannerImageSection{
          width: 100px;
          height: 100px;
          margin-bottom: 10px;
        }
        .pageHeaderLeft {
          width: 100%;
        }
        .bannerLink{
          display: block;
        }
        .bannerLink p,  .details p,  .bannerDetails p , .bannerContent p{
          font-size: 13px;
          word-break: break-word;
        }
        .details{
          width: 100%;
        }
    }