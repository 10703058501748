.main {
    width: 100%;
    font-family: Poppins;
    padding-bottom: 2%;
  }
  
  .contentContainer {
    width: 97.5%;
    box-shadow: 0px 1px 8px 0px #00000026;
    margin-left: 1%;
    padding: 1%;
    border-radius: 12px;
    font-family: Poppins;
    display: flex;
  }
  
  .leftContainer {
    width: 50%;
  }

  .rightContainer {
    width: 50%;
    

  }
  
  .fieldContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .fieldLabel{
    margin-top: 3%;
  }
  .fieldLabel p {
    margin: 0;
    font-size: 0.8rem;
    font-weight: 500;
  }
  .fieldInput {
    width: 80%;
    margin-top: 2%;
  }
  
  .fieldInput input{
      background-color: #FFF9F1;
      outline: none;
      border: none;
      width: 100%;
      height: 3rem;
      border-radius: 8px;
      padding-left: 2%;
      font-size: 0.8rem;
  }

  .fileInput{
    background-color: #FFF9F1;
    outline: none;
    border: none;
    width: 100%;
    height: 3rem;
    border-radius: 8px;
  }
  
  .fieldInput textarea{
    background-color: #FFF9F1;
    outline: none;
    border: none;
    width: 100%;
    height: 5rem;
    border-radius: 8px;
    resize: none;
    padding-left: 2%;
    font-size: 0.8rem;
  }

  .uploadIcon{
    margin-top: 2%;
    margin-left: 90%;    
  }

  .fieldRadioInput{
    display: flex;
    gap: 10px;
    align-items: center
  }
 
  
  .footer{
    margin-top: 5%;
  }

  .error{
    margin: 0;
    margin-top: 0.5rem;
    font-size: 0.7rem;
    color: red;
  }
  
  input[type="text"]::placeholder {
    font-size: 0.7rem;
    padding-left: 20px;
    }
  
  input[type="date"] {
  appearance: none !important;
  }
  
  
  input[type="date"]:focus {
    outline: none;
  }

  .userList{
    background-color:#fff9f1;
  }

  .userList > input {
    width: 98%;
    height: 3rem;
    padding-left: 10px;
    background-color: white;
    border: none;
    margin-top: 1%;
   margin-left: 1%;
   outline: none;
   font-size: 0.7rem;
  }



  .notificationTable {
    width: 100%;
    background-color:#fff9f1;
  }
  
  .notificationTable > table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 0% !important;
    table-layout:unset !important;
    padding: 1%;
  }
  
  .notificationTable th,
  .notificationTable td {
    text-align: center !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 0.6rem !important;
    font-weight: 500;
  }
  
  
  .notificationTable > table > tbody > tr:not(:last-child) {
    border-bottom: 1px dashed #D9DEEA
  }
  .notificationTable tbody > tr{
    border-radius: 0px;
  }
  
  .notificationTable tr {
    margin-bottom: 10px !important;
  }
  
  .notificationTable td {
    width: 15%;
    max-width: 40%;
    padding: 0.5% !important;
    height: 2.5rem !important;
  }
  
  
  .notificationTable td:nth-child(3){
    width: 40% !important; 
  }

  
@media screen and (max-width: 767px) {

  .contentContainer {
    flex-direction: column;
  }
  .createNotiPara{
    margin-top: 10%;
  }
 
  .btnSaveDiscard{
    width:60% !important;
  }
  .pageHeader {
    display: block;
    justify-content: space-between;
    padding: 2% 2% 3% 0 !important;
    background-color: #fcfcfc;
  }
  .pageHeaderLeft {
    width: 100%;
  }
  .leftContainer {
    width: 100%;
  }

  .fieldInput {
    width: 100%;
    margin-top: 2%;
  }
  .notificationTable{
    width: 100%;
    overflow-x:scroll;
  }
 
  .fieldUserContainer{
    width:95vw;
  }
  .notificationTable {
    width: 95vw;

  }
  
  .pageHeaderRight {
 margin-left: 1.5%;
  }
  
}