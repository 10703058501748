.fileInput {
  background-color: #fff9f1;
  outline: none;
  border: none;
  width: 100%;
  height: 3rem;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding-left: 2%;
}

.uploadIcon {
  display: flex;
  width: 10%;
  justify-content: flex-end;
  align-items: center;
  padding-right: 2%;
}

.fileName {
  display: inline-block;
  width: 90%;
  font-size: 0.8rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
