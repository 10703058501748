.main {
    width: 100%;
    font-family: Poppins;
    padding-bottom: 2%;
  }
  
  .pageHeader {
    display: flex;
    justify-content: space-between;
    padding: 2% 2% 3% 0 !important;
    background-color: #fcfcfc;
  }
  
  .pageHeaderLeft {
    width: 60%;
  }
  
  .pageHeaderRight {
    width: 40%;
    display: flex;
    justify-content: flex-end;
    gap: 2%;
  }
  .pageHeaderLeft p {
    font-weight: 600;
    font-size: 1.1rem;
    padding-left: 1.5%;
    width: 100%;
  }
  
  .activePageStyle {
    color: #ff9914;
    margin-left: 1.5%;
  }
  .pageHeaderRight button {
    font-weight: 400;
    font-size: 0.7rem;
    color: #ffffff;
    background: linear-gradient(95.2deg, #FF9914 0%, #FF4C04 54.17%);
    border: none;
    width: 22%;
    padding: 0 2%;
    height: 5.5dvh;
    border-radius: 8px;
  }
.detailMainContainer{
    width: 98%;
    margin-left: 1%;
    display: flex;
    flex-direction: column;
    padding-bottom: 4%;
}


@media (max-width:767px){
  .main{
    margin-top: 50px;
  }
  .pageHeader{
    display: block;
  }
  .pageHeaderLeft{
    width: 100%;
  }
  .pageHeaderRight {
     width: 100%;
  }
}