.main {
    width: 100%;
    font-family: Poppins;
    padding-bottom: 2%;
  }
  
  .pageHeader {
    display: flex;
    justify-content: space-between;
    padding: 2% 2% 3% 0 !important;
    background-color: #fcfcfc;
  }
  
  .pageHeader p {
    font-weight: 600;
    font-size: 1.1rem;
    padding-left: 0.5%;
    width: 50%;
  }
  
  .activePageStyle {
    color: #ff9914;
    margin-left: 1.5%;
  }
  .pageHeader button {
    font-weight: 400;
    font-size: 0.7rem;
    color: #ffffff;
    background: linear-gradient(95.2deg, #ff9914 0%, #ff4c04 54.17%);
    border: none;
    padding: 0 3%;
    height: 5.5dvh;
    border-radius: 8px;
  }
  
  .contentContainer {
    width: 97.5%;
    box-shadow: 0px 1px 8px 0px #00000026;
    margin-left: 1%;
    padding: 1%;
    border-radius: 12px;
    font-family: Poppins;
  }
  
  .filterExport {
    width: 100%;
    display: flex;
    gap: 2%;
    align-items: center;
    justify-content: center;
    padding: 0 0 1% 0;
  }
  
  .filterExport p {
    width: 50%;
    margin: 0;
  }
  
  .search {
    width: 13%;
  }
  
  .search input {
    width: 100%;
    background-color: #fff9f1;
    border-radius: 8px;
    /* background-image: url(../../../assets/images/searchIcon.png); */
    background-repeat: no-repeat;
    background-size: 10%;
    background-position-x: 90%;
    background-position-y: 45%;
    padding: 3%;
    font-size: 0.8rem;
    outline: none;
    border: none;
  }
  
  .search input:focus {
    background-image: none;
  }
  
  .export {
    border: 1px solid #e6e6e6;
    border-radius: 15px;
    width: 20%;
    cursor: pointer;
  }
  
  .export p {
    width: 100%;
    margin: 0;
    padding: 2.5%;
    font-size: 0.7rem;
    font-weight: 600;
    text-align: center;
  }
  
  .export > p > a{
    text-decoration: none;
    color: #000000;
  }
  
  .filterDiv {
    border: 1px solid #e6e6e6;
    border-radius: 15px;
    width: 8%;
  }
  
  .filterDiv p {
    width: 100%;
    margin: 0;
    padding: 5% 0;
    font-size: 0.7rem;
    font-weight: 600;
    text-align: center;
    /* background-image: url(../../../assets/images/filter.png); */
    background-size: 14px;
    background-position-x: 18%;
    background-position-y: 50%;
    background-repeat: no-repeat;
  }
  
  .activePage {
    margin-top: 3%;
  }
  
  .tableContainerTask {
    width: 99%;
    border-radius: 8px;
  }
  
  .tableContainerTask > table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 0% !important;
    table-layout:unset !important;
    margin-left: 1%;
  }
  
  th,
  td {
    text-align: center !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 0.8rem;
  }
  
  thead tr {
    box-shadow: 0px 0px 4px 0px #ff99144d !important;
    border-radius: 8px;
  }
  
  tbody tr {
    box-shadow: 0px 0px 2px 0px #999fa980;
    border-radius: 8px;
  }
  
  tr {
    margin-bottom: 10px !important;
  }
  
  td {
    width: 15%;
    max-width: 60%;
    padding: 1.5%;
  }
  
  th {
    padding: 1%;
    font-size: 0.8rem;
    font-weight: 500;
    letter-spacing: 0.4px;
    color: #ff9914;
    position: relative;
  }
  
  th:not(:last-child)::after {
    content: "";
    position: absolute;
    top: 25%;
    right: 0px;
    bottom: 0;
    width: 2px;
    height: 3dvh;
    background-color: #E6E6E6 !important;
  }
  
  .tableContainerTask td:nth-child(1){
    width: 15% !important; 
  }

 .tableContainerTask td:nth-child(2){
    width: 25% !important; 
  }
 .tableContainerTask td:nth-child(3){
    width: 30% !important; 
  }
 .tableContainerTask td:nth-child(4){
    width: 30% !important; 
  }
  .tdIcon img{
    width: 15% !important; 
    height: 15% !important;
  }

  
  .footer {
    width: 98%;
    margin-left: 1%;
    display: flex;
    justify-content: space-between;
    margin-top: 2%;
  }
  .action {
    display: flex;
    width: 50%;
    align-items: center;
    padding-bottom: 2%;
    gap: 2%;
  }
  
  .actionButton {
    width: 20%;
    text-align: center;
    padding: 1.5%;
    border-radius: 22px;
    border: 1px solid #999fa9;
    font-size: 0.8rem;
    cursor: pointer;
    transition: all 0.3s ease; 
  }

  .actionTd img{
    width:15px;
    height:15px;
    margin-right:15px;
    cursor: pointer;
  }
  
  .activeButton {
    background-color: #ff9914;
    color: #ffffff;
    font-size: 0.8rem;
    border: none !important;
  }
  
  .page {
    width: 50%;
    text-align: right;
  }
  
  .page span {
    font-size: 0.8rem;
  }
  
  .page span:first-child {
    font-size: 0.8rem;
    padding: 0.3% 1.6%;
    border: 1px solid #00054f26;
    border-radius: 5px;
  }
  
  .tableviewDetails {
    color: #ff9914;
    text-decoration-line: underline;
    cursor: pointer;
  }
  
  .viewDetails{
    color: #ff9914;
    text-decoration-line: underline;
    cursor: pointer;
  }
  .pageHeaderRight{
    width: 40%;
    display: flex;
    justify-content: flex-end;
  }
  
  .pageHeaderRight button {
    font-weight: 400;
    font-size: 0.7rem;
    color: #ffffff;
    background: linear-gradient(95.2deg, #ff9914 0%, #ff4c04 54.17%);
    border: none;
    padding: 0 3.5%;
    height: 5.5dvh;
    border-radius: 8px;
  }
  @media screen and (max-width: 767px) {
  .taskHeader{
    margin-top: 5%;
  }
    .tableContainerTask{
      width: 100%;
      overflow-x: auto !important;
    }
    table {
      width: 180dvw !important;
      border-collapse: collapse;
      margin-top: 5%;
      table-layout:auto !important;
      margin-left: 1%;
    }
  } 
  