.main {
  width: 100%;
  font-family: Poppins;
  padding: 0 1%;
}

.pageHeader {
  display: flex;
  justify-content: space-between;
  padding: 2% 2% 3% 0 !important;
  background-color: #fcfcfc;
}
.pageHeaderLeft {
  width: 60%;
}

.pageHeaderLeft p {
  font-weight: 600;
  font-size: 1.1rem;
  padding-left: 1.5%;
  width: 100%;
}

.pageHeaderRight {
  width: 40%;
  display: flex;
  justify-content: flex-end;
}

.contentContainer {
  width: 98%;
  box-shadow: 0px 1px 8px 0px #00000026;
  margin-left: 1%;
  padding: 1%;
  border-radius: 12px;
  font-family: Poppins;
}

.userActivityTable {
  width: 100%;
  margin-top: 2%;
  border-radius: 8px;
}

.userActivityTable table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 0% !important;
  table-layout: fixed;
  margin-left: 0 !important;
}

.userActivityTable th,
td {
  text-align: center !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 0.7rem;
}

.userActivityTable thead tr {
  box-shadow: 0px 0px 4px 0px #ff99144d !important;
  border-radius: 8px;
}

.userActivityTable tbody tr {
  box-shadow: 0px 0px 2px 0px #999fa980;
  border-radius: 8px;
}

.userActivityTable tr {
  margin-bottom: 10px !important;
}

.userActivityTable td {
  width: 25%;
  max-width: 25%;
  padding: 1.5%;
}

.userActivityTable td:nth-child(1) {
  width: 10% !important;
}


.userActivityTable th {
  padding: 1%;
  font-size: 0.7rem;
  font-weight: 500;
  letter-spacing: 0.4px;
  color: #ff9914;
  position: relative;
}

.userActivityTable th:not(:last-child)::after {
  content: "";
  position: absolute;
  top: 25%;
  right: 0px;
  bottom: 0;
  width: 2px;
  height: 3dvh;
  background-color: #e6e6e6 !important;
}

.footer {
  width: 98%;
  margin-left: 1%;
  display: flex;
  justify-content: space-between;
  margin-top: 2%;
}
.action {
  display: flex;
  width: 50%;
  align-items: center;
  padding-bottom: 2%;
  gap: 2%;
}

.actionButton {
  width: 20%;
  text-align: center;
  padding: 1.5%;
  border-radius: 22px;
  border: 1px solid #999fa9;
  font-size: 0.8rem;
  transition: all 0.3s ease;
  cursor: pointer;
}

.page {
  width: 50%;
  text-align: right;
}

.page span {
  font-size: 0.8rem;
}

.page span:first-child {
  font-size: 0.8rem;
  padding: 0.3% 1.6%;
  border: 1px solid #00054f26;
  border-radius: 5px;
}
.filterDiv {
  border: 1px solid #e6e6e6;
  border-radius: 15px;
  width: 8%;
}

.filterDiv p {
  width: 100%;
  margin: 0;
  padding: 5% 0 0 28%;
  font-size: 0.7rem;
  font-weight: 600;
  text-align: center;
  background-image: url(../../assets/images/filter.png);
  background-size: 14px;
  background-position-x: 18%;
  background-position-y: 50%;
  background-repeat: no-repeat;
}

.filterExport {
  width: 100%;
  display: flex;
  gap: 2%;
  /* align-items: center; */
  justify-content: flex-end;
  padding: 0 0 1% 0;
}

.filterExport p {
  width: 50%;
  margin: 0;
}

@media screen and (max-width: 767px) {
  .filterDiv p {
    padding: 4% 28%;
    background-size: 12px;
    background-position-x: 25%;
    background-position-y: 50%;
    background-repeat: no-repeat;
  }
  .filterDiv{
    width: 30%;
  }
  .filterExport {
    width: 100%;
    display: flex;
    gap: 2%;
    justify-content: flex-end;
    padding: 0 0 1% 0;
  }
  
  .filterExport p {
    width: 50%;
    margin: 0;
  }
  
} 