.reasonButton{
    color: red;
    font-size: 0.8rem;
    padding: 1% 2%;
    border-radius: 5px;
    background: #FF000033;
    font-family: Roboto;
    margin: 0;
    cursor: pointer;
}


.lableName{
    width: 100%;
    margin-top: 4%;
}

.lableName p{
    font-size: 1.1rem;
    font-weight: 600;
    margin: 0;
    font-family: Roboto;
}

.reasonTwo{
    width: 100%;
}
.reason{
    width: 100%;
    margin-top: 4%;
    background-color: #ebebeb;
    border: 1px solid #fdfdfd;
    border-radius: 5px;
    padding-left: 10px;
}

.reason p {
    width: 100%;
    font-size: 0.9rem;
    padding: 3% 0;
    margin: 0;
}

.action{
    width: 100%;
    margin-top: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.action button{
    width: 20%;
    border: 1px solid black;
    border-radius: 18px;
    outline: none;
    background: #ffffff;
}